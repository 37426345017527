export const ROOT = '/warehouse/:warehouse_id/dashboard'
export const LOGIN = '/login'
export const ORDERS = '/warehouse/:warehouse_id/orders'
export const ORDERS_SHIPPED = '/warehouse/:warehouse_id/orders_shipped'
export const REVERSE_LOGISTICS = '/warehouse/:warehouse_id/reverse_logistics'
export const ORDERS_PROCESSING = '/warehouse/:warehouse_id/orders_processing'
export const PROCESSING_ORDER_DETAIL = '/warehouse/:warehouse_id/orders_processing/detail/:id'
export const PICK_N_SHIP = '/warehouse/:warehouse_id/pick-n-ship-orders'
export const SHOW_PICK_N_SHIP_ORDER = '/warehouse/:warehouse_id/pick-n-ship-orders/show/:id'
export const GENERATE_LABEL= '/warehouse/:warehouse_id/orders/generate_label/:id'
export const PROCESSING_GENERATE_LABEL= '/orders_processing/generate_label/:id'
export const GENERATE_ORDER_LABEL= '/warehouse/:warehouse_id/generate_label/:id'
export const GENERATE_LABELS= '/warehouse/:warehouse_id/generate_labels'
export const GENERATE_SHARED_LABEL= '/warehouse/:warehouse_id/generate_shered_label'
export const SHOW_ORDERS = '/warehouse/:warehouse_id/orders/show/:id'
export const INVENTORY = '/warehouse/:warehouse_id/inventory'
export const REPLENISHMENTS = '/warehouse/:warehouse_id/replenishments/'
export const SHOW_REPLENISHMENT = '/warehouse/:warehouse_id/replenishments/detail/:id'
export const WAREHOUSES = '/'

export const BILLING = '/warehouse/:warehouse_id/billing'
export const BILLING_STORE_CONFIGS = '/warehouse/:warehouse_id/billing/configs/:store_id'
export const BILLING_STORE_CONFIG_CREATE = '/warehouse/:warehouse_id/billing/configs/:store_id/create'
export const BILLING_STORE_CONFIG_DUPLICATE = '/warehouse/:warehouse_id/billing/configs/:store_id/duplicate/:billing_configuration_id'
export const BILLING_STORE_CREATE_CUSTOM_SERVICE = '/warehouse/:warehouse_id/billing/configs/:store_id/create_custom_service/:billing_configuration_id'

export const CLAIMS = '/warehouse/:warehouse_id/claims'
export const SHOW_CLAIM = '/warehouse/:warehouse_id/claims/detail/:id'

export const OPERATORS = '/warehouse/:warehouse_id/operators'
export const SHOW_OPERATORS = '/warehouse/:warehouse_id/operators/detail'

export const CREATE_RETURN = '/warehouse/:warehouse_id/orders_shipped/:orderId/create-return'
export const SHOW_CREATING_RETURN_ORDER = '/warehouse/:warehouse_id/orders_shipped/:orderId/create-return/show-order/:id'
export const CREATE_RETURN_REVERSE_LOGISTICS = '/warehouse/:warehouse_id/reverse_logistics/:orderId/create-return'
export const SHIPPED_ORDER_DETAIL = '/warehouse/:warehouse_id/orders_shipped/detail/:id'
export const SHIPPED_ORDER_DETAIL_REVERSE = '/warehouse/:warehouse_id/reverse_logistics/detail/:id'

export const SEARCH_RETURN = '/warehouse/:warehouse_id/returns/:scann_string/search'
export const RECEIVE_RETURNED_ORDER = '/warehouse/:warehouse_id/returns/order/:order_id/receive'

export const ORDER_DETAIL = '/warehouse/:warehouse_id/:origin/detail/:id'

export const RETURNS = '/warehouse/:warehouse_id/returns'
export const SHOW_RETURN = '/warehouse/:warehouse_id/returns/detail/:id'
export const SHOW_ORDER_RETURNS = '/warehouse/:warehouse_id/returns/show-order/:id'
export const RECEIVE_RETURN = '/warehouse/:warehouse_id/returns/:id/receive'
export const SHOW_RECEIVING_ORDER = '/warehouse/:warehouse_id/returns/:returnId/receive/show-order/:id'

export const LOCATIONS = '/warehouse/:warehouse_id/locations'

export const INVENTORY_HISTORY = '/warehouse/:warehouse_id/inventory_history'

export const WORK_ORDERS = '/warehouse/:warehouse_id/work-orders'
export const SHOW_WORK_ORDER = '/warehouse/:warehouse_id/work-orders/detail/:id'

export const ACCESS_DENIED = '/access_denied'

export const STORE_BILLING_STATUS_ACTIVE = 'ACTIVE'
export const STORE_BILLING_STATUS_DISABLED = 'DISABLED'
export const STORE_BILLING_STATUS_UNREQUIRED = 'UNREQUIRED'
export const STORE_BILLING_STATUS_PENDING = 'PENDING'