import { useQuery } from "react-query";
import { fetchStore } from "../../services";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BasicTable, Loader, Button, ConfirmDialog, Notification } from "../../components";
import { useContext, useEffect, useRef, useState } from 'react';
import { UserContext } from '../../hooks/UserContext';
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { disableBillingAtCutoff, fetchStoreBillingConfigurations } from "../../services/storeService"
import { BILLING_STORE_CONFIG_CREATE, BILLING_STORE_CONFIG_DUPLICATE, BILLING_STORE_CREATE_CUSTOM_SERVICE, STORE_BILLING_STATUS_ACTIVE, STORE_BILLING_STATUS_UNREQUIRED } from "../../navigation/constants"
import ListCustomServicesContainer from "./ListCustomServicesContainer"

export const ShowStoreBillingConfigsContainer = () => {

    const { store_id } = useParams()
    const {warehouse_id} = useParams()
    const { t, i18n} = useTranslation()
    const {user} = useContext(UserContext)
    const [currentWarehouse, setCurrentWarehouse] = useState(null)
    const [billingConfigurations, setBillingConfigurations] = useState([])
    const [showConfirmDialog, setShowConfirmDialog] = useState(false)
    const [notificationMessage, setNotificationMessage] = useState({show: false, title: "", type: ""})
    const [busy, setBusy] = useState(false)
    const history = useHistory()
    const tableRef = useRef()
    const {
        isLoading,
        isFetching,
        isError,
        error,
        data,
        isPreviousData,
        refetch
    } = useQuery(['store', store_id], ()=>fetchStore(store_id), {
        keepPreviousData: true,
        refetchOnWindowFocus: false
    })
    const {
        isLoading: isLoadingBillingConfigurations,
        isFetching: isFetchingBillingConfigurations,
        isError: isErrorBillingConfigurations,
        error: errorBillingConfigurations,
        data: dataBillingConfigurations,
        isPreviousData: isPreviousDataBillingConfigurations,
        refetch: refetchBillingConfigurations
    } = useQuery(['store_billing_configurations', store_id], ()=>fetchStoreBillingConfigurations({store_id: store_id}), {
        keepPreviousData: true,
        refetchOnWindowFocus: false
    })

    const getCurrentWarehouse = () => {
        const warehouse = data?.warehouses.find(warehouse => warehouse.id == warehouse_id)
        setCurrentWarehouse(warehouse)
    }

    useEffect(() => {
        getCurrentWarehouse()
    }, [data])

    useEffect(() => {
        dataBillingConfigurations && setBillingConfigurations(dataBillingConfigurations.store_billing_configurations)
    }, [dataBillingConfigurations])
    
    const navigate = (billing_configuration_id) => {
        history.push(BILLING_STORE_CONFIG_DUPLICATE.replace(':warehouse_id', warehouse_id).replace(':store_id', store_id).replace(':billing_configuration_id', billing_configuration_id))
        
    }

    const columns = [
        {
            accessor: 'id',
            Header: "ID",
            disableFilters: false,
            disableSortBy: false,
            searchAs: 'name'
        },
        {
            accessor: 'status',
            Header: t('generic.status'),
            disableFilters: true,
            disableSortBy: true,
            shrinkToContent: true
        },
        {
            accessor: 'from',
            Header: t('generic.from'),
            disableFilters: true,
            disableSortBy: true,
        },
        {
            accessor: 'to',
            Header: t('generic.to'),
            disableFilters: false,
            disableSortBy: true,
            searchAs: 'account_name'
        },
        {
            accessor: 'statement_count',
            Header: t('generic.billing_statements'),
            disableFilters: true,
            disableSortBy: false
        },
        {
            accessor: 'actions',
            Header: '',
            fetchingIndicator : true,
            disableFilters: true,
            disableSortBy: true,
            shrinkToContent: true
        },
    ]

    const formatDate = (date) => {
        if(!date)
            return t('generic.undefined')
        const d = new Date(date)
        return d.toLocaleDateString([], {year: '2-digit', month: '2-digit', day: '2-digit'})
    }

    const hasFutureBillingConfiguration = () => {
        return billingConfigurations.some(billing_configuration => {
            const dateNow = new Date()
            const date_to = billing_configuration.valid_to
            const date_from = billing_configuration.valid_from
            return new Date(date_from) > dateNow
        })
    }

    const hasCurrentBillingConfiguration = () => {
        return currentBillingConfiguration ? true : false
    }

    const canBillingBeDisabled = () => {
        if(!hasCurrentBillingConfiguration())
            return false
        else if(hasFutureBillingConfiguration())
            return true
        else return currentBillingConfiguration.valid_to ? false : true
        
    }

    const getStatusCapsule = (billing_configuration) => {
        const dateNow = new Date()
        const date_to = billing_configuration.valid_to
        const date_from = billing_configuration.valid_from
        let status = ""
        let color = ""
        if(!date_to) {
            if (new Date(date_from) > dateNow) {
                status = "future"
                color = "blue"
            }
            else {
                status = "ongoing"
                color = "green"
            }
        }
        else if (new Date(date_to) > dateNow && new Date(date_from) < dateNow) {
            status = "ongoing"
            color = "green"
        }
        else if (new Date(date_to) < dateNow) {
            status = "billed"
            color = "gray"
        }
        else {
            status = "future"
            color = "blue"
        }
        return (
            <span className={`px-2 inline-flex text-xs leading-5 rounded-full bg-${color}-200 text-${color}-500`}>
                {t(`billing.${status}`)}
            </span>
        )
    }


    const getTableData = () => {
        return billingConfigurations.map(billing_configuration => {
            return {
                ...billing_configuration,
                status: getStatusCapsule(billing_configuration),
                from: formatDate(billing_configuration.valid_from),
                to: formatDate(billing_configuration.valid_to),
                actions: (
                    <Link to={BILLING_STORE_CONFIG_DUPLICATE.replace(':warehouse_id', warehouse_id).replace(':store_id', store_id).replace(':billing_configuration_id', billing_configuration.id)} className="text-black hover:text-black mt-2">
                        <button className="text-left whitespace-nowrap border border-gray-300 shadow-sm rounded-lg py-3 px-3 text-gray-700 font-medium hover:bg-gray-50 outline-none">
                            <span className="text-sm">
                                {t('billing.duplicate')}
                            </span>
                        </button>
                    </Link>
                ),
            }
        }) 
    }

    const disableBillingForStore = async (store_id) => {
        setBusy(true)
        try {
            await disableBillingAtCutoff(store_id)
            setNotificationMessage({show: true, type: "success", title: i18n.t("billing.disable_billing_for_store_success")})
        } catch (error) {
            alert(error)
            console.log(error)
        } finally {
            setBusy(false)
            setShowConfirmDialog(false)
            refetchBillingConfigurations()
        }
    }

    const buildCreateConfigLink = () => {
        let pageLink = null

        if(billingConfigurations?.length > 0){
            pageLink = BILLING_STORE_CONFIG_DUPLICATE.replace(':warehouse_id', warehouse_id).replace(':store_id', store_id).replace(':billing_configuration_id', billingConfigurations[0].id)
        } else{
            pageLink = BILLING_STORE_CONFIG_CREATE.replace(':warehouse_id', warehouse_id).replace(':store_id', store_id)
        }

        return pageLink
    }

    const currentBillingConfiguration = billingConfigurations.find((billingConfiguration) => {
        const dateNow = new Date()
        const dateTo = billingConfiguration.valid_to
        const dateFrom = billingConfiguration.valid_from

        const isCurrentConfig = (new Date(dateFrom)) <= dateNow && (!dateTo || (new Date(dateTo)) >= dateNow) ? true : false
        return isCurrentConfig
    })

    return (
        <>
            {isLoading || isLoadingBillingConfigurations ? (
                <><Loader show={true}></Loader></>
            ) : isError ? (
                <>Error: {error.message}</>
            ) : (
                <div className="pb-6">
                    <div className="flex flex-col rounded-lg border border-gray-300">
                        <div className="flex border-b border-gray-300 p-4">
                            <div className="w-1/3 font-semibold">{t('generic.store')}</div>
                            <>{data.name}</>
                        </div>
                        <div className="flex border-b border-gray-300 p-4">
                            <div className="w-1/3 font-semibold">{t('generic.country')}</div>
                            <>{currentWarehouse?.country == "BR" ? "Brasil" : "México"}</>
                        </div>
                        <div className="flex p-4">
                            <div className="w-1/3 font-semibold">{t('generic.warehouse')}</div>
                            <>{currentWarehouse?.name}</>
                        </div>
                    </div>

                    <div className="mt-6 mb-2">
                        <div className="flex justify-between items-center">
                            <h2 className="font-bold text-lg my-6 mb-4">{t('billing.billing_custom_services')}</h2>
                            <Button
                                disabled={data.billing_status != STORE_BILLING_STATUS_ACTIVE}
                                type="primary"
                                onClick={() => {
                                    history.push(BILLING_STORE_CREATE_CUSTOM_SERVICE.replace(':warehouse_id', warehouse_id).replace(':store_id', store_id).replace(':billing_configuration_id', currentBillingConfiguration?.id))
                                }}
                            >
                                {t("billing.custom_services.create_button")}
                            </Button>
                        </div>


                        <ListCustomServicesContainer />
                    </div>

                    <div className="flex justify-between items-center mt-6 mb-2">
                        <h2 className="my-6 mb-4 font-bold text-lg">{t('billing.billing_configurations_history')}</h2>
                        <Link to={buildCreateConfigLink} className={`text-black hover:text-black mt-2 ${data.billing_status == STORE_BILLING_STATUS_UNREQUIRED ? 'opacity-50' : ''}`}>
                            <button className="bg-blue-500 hover:bg-blue-600 rounded-lg px-4 py-2"
                                disabled={data.billing_status == STORE_BILLING_STATUS_UNREQUIRED}>
                                <span className="text-sm text-white">
                                    {t('billing.create')}
                                </span>
                            </button>
                        </Link>
                    </div>
                    <BasicTable
                        columns={columns}
                        showHeader
                        selectable = {false}
                        showCount = {true}
                        showLoader={isFetching}
                        data = {getTableData()}
                        emptyTableText={t('generic.empty_table')}
                        ref = {tableRef}
                    />
                    <div className="flex flex-col mt-6 mb-2">
                        <h2 className="my-6 mb-4 font-bold text-lg">{t('billing.disable_title')}</h2>
                        <div>
                            <Button type="danger" onClick={() => setShowConfirmDialog(true)} disabled={!canBillingBeDisabled()}>
                                {t('billing.disable')}
                            </Button>
                        </div>
                    </div>
                    <ConfirmDialog
                        open={showConfirmDialog}
                        setOpen={(val) => setShowConfirmDialog(val)}
                        cancelLabel= {i18n.t("billing.confirm_disable_dialog.cancel")}
                        confirmLabel= {i18n.t("billing.confirm_disable_dialog.confirm")}
                        description={` ${i18n.t("billing.confirm_disable_dialog.description")}`}
                        title= {i18n.t("billing.confirm_disable_dialog.title")} 
                        loading={busy}
                        onConfirm={() => disableBillingForStore(data.id)}
                    />
                    <Notification
                        show={notificationMessage.show}
                        title={notificationMessage.title}
                        type={notificationMessage.type}
                        setShow={showNotification => setNotificationMessage({...notificationMessage, show: showNotification})}
                    />
                </div>
            )}
        </>
    )
};