import { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { cloneDeep } from "lodash"
import { CheckCircleIcon, ExclamationCircleIcon } from "@heroicons/react/outline"

import RangeBillingPriceConfigTable from "../RangeBillingPriceConfigTable"
import { SERVICE_TYPES } from "../constants"
import { findOrCreateBillingService, getCurrency } from "../utils"
import { UNITS_IDS } from "../RangeBillingPriceConfigTable/constants"

const WorkOrdersServices = ({storeBillingServices, onUpdateStoreBillingServices, onIsValid, storeId, warehouse}) => {
    const { i18n } = useTranslation()
    const [isWorkOrderServiceValid, setIsWorkOrderServiceValid] = useState(false)

    const workOrderService = findOrCreateBillingService({serviceType: SERVICE_TYPES.SERVICE_WORK_ORDER, billingServices: storeBillingServices, currency: getCurrency(warehouse?.country), storeId: storeId, warehouseId: warehouse?.id})

    const workerOrderTableData = workOrderService?.price_configuration.map(priceRange => {
        return {
            from: {value: priceRange.from, isDisabled: true},
            to: {value: priceRange.to, isDisabled: true},
            price: {value: priceRange.price},
            price_mode: {value: priceRange.price_mode},
            excess: {value: priceRange.excess},
            range_exclusion: {value: priceRange.range_exclusion},
        }
    })

    const tableHeaders = [
        {label: i18n.t("billing.table_headers.from_piece"), id: 'from'},
        {label: i18n.t("billing.table_headers.to_piece"), id: 'to'},
        {label: i18n.t("billing.table_headers.price"), id: 'price'},
        {label: i18n.t("billing.table_headers.unit"),id: 'unit'}
    ]

    const unitOptionsLabels = {
        PER_UNIT: i18n.t("billing.work_orders_services.unit_options.PER_UNIT")
    }

    const handleOnUpdateData = (serviceType, newTableData, service) => {
        let newStoreBillingServices = cloneDeep(storeBillingServices)

        const newPriceConfiguration = newTableData.map(priceRangeData => {
            return {
                from: priceRangeData.from.value,
                to: priceRangeData.to.value,
                price: priceRangeData.price.value,
                price_mode: priceRangeData.price_mode.value,
                excess: priceRangeData.excess.value,
                range_exclusion: priceRangeData.range_exclusion.value
            }
        })

        const serviceExists = newStoreBillingServices.some(({service_type}) => service_type === serviceType)

        if(serviceExists){
            newStoreBillingServices = newStoreBillingServices.map(billingService => {
                if(billingService.service_type === serviceType){
                    billingService.price_configuration = newPriceConfiguration
                }
                return billingService
            })
        } else{
            const newService = cloneDeep(service)
            newService.price_configuration = newPriceConfiguration
            newStoreBillingServices.push(newService)
        }

        onUpdateStoreBillingServices(newStoreBillingServices)
    }

    useEffect(() => {
        onIsValid(isWorkOrderServiceValid)
    }, [isWorkOrderServiceValid])

    return (
        <div>
            <div className="flex gap-1">
                <h2 className="text-xl text-gray-500"><span className="text-red-500">*</span>{i18n.t("billing.work_orders_services.work_order_title")}</h2>

                {isWorkOrderServiceValid ? (
                    <CheckCircleIcon className="h-5 w-5 text-green-500"/>
                ) : (
                    <ExclamationCircleIcon className="h-5 w-5 text-yellow-500"/>
                )}
            </div>

            <RangeBillingPriceConfigTable
                headers={tableHeaders}
                priceConfiguration={workerOrderTableData}
                currency={getCurrency(warehouse?.country)}
                onUpdatePriceConfiguration={newTableData => handleOnUpdateData(SERVICE_TYPES.SERVICE_WORK_ORDER, newTableData, workOrderService)}
                onIsValid={setIsWorkOrderServiceValid}
                availableUnitOptions={[UNITS_IDS.PER_UNIT]}
                unitOptionsLabels={unitOptionsLabels}
            />
        </div>
    )
}

export default WorkOrdersServices