import axiosInstance from "../helpers/axiosInstance";


export const fetchWarehouses = async (params = {}) => {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/warehouses`, { params })
    return res.data
}

export const fetchWarehousesOpen = async () => {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/warehouses_open`)
    return res.data
}

export const fetchWarehouse = async (id, params = {}) => {
    if(id==null)
        return null
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/warehouses/${id}`, {params})
    return res.data
}

export const fetchWarehouseShippingMethods = async (id = {}) => {
    if(id==null)
        return null
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/warehouses/${id}/shipping-methods`)
    return res.data
}

export const fetchWarehouseVolumeCategories = async (warehouseId) => {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/warehouses/${warehouseId}/volume-categories`)
    return res.data
}


export const fetchReturnsSummary = async (warehouseId) => {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/warehouses/${warehouseId}/returns-summary`)
    return res.data
}
