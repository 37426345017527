export const es = {
    general: {
        product: {
            insert: "Injerto",
        }
    },

    inputPlaceholder: {
        pdfFiles: "Presiona aquí o arrastra tus archivos PDF.",
        addImage: "Presiona aquí o arrastra imágenes.",
        select: "Seleccionar",
        required_field: "Este campo es requerido"
    },
    claims:{
        motives:{
            delivery_delay: 'El pedido ha sido recolectado y no presenta movimiento',
            false_delivery: 'El estatus del pedido indica que fue entregado, pero mi cliente no lo recibió',
            package_stolen: 'El estatus del pedido indica que fue robado.',
            package_lost: 'El estatus del pedido indica que fue extraviado.',
            damaged_product: 'Mi cliente recibió productos dañados o en mal estado.',
            incorrect_content: 'Lo que recibió mi cliente, no coincide con lo que ordenó',
            none_of_the_above: 'Ninguno de los anteriores',
            missing_products: 'Faltaron productos en el pedido',
            unwanted_products: 'Se recibieron productos no ordenados',
            wrong_order: 'Mi cliente recibió un pedido completo que no era el suyo'
        },
        reimbursables:{
            /*
            cost_cubbo: "Costo operativo de Cubbo",
            cost_shipping: "Costo de guía del pedido",
            product_value: "Valor indicado del producto",
            cost_shipping_replace: "Costo de guía para enviar reemplazo de pedido",
            cost_shipping_reposition: "Costo de guía para enviar reposición de pedido",
            return_cost: 'Costo de la guía de retorno del producto incorrecto'
            */
            cost_cubbo: "Costo procesamiento del pedido",
            cost_shipping: "Costo de guía de envío",
            product_value: "Precio de venta de los productos",
            cost_return_shipping: "Precio de guía de retorno",
            cost_shipping_replace: "Costo de guía para enviar reemplazo de pedido",
            cost_shipping_reposition: "Costo de guía para enviar reposición de pedido",
            return_cost: 'Costo de la guía de retorno del producto',
            other: 'Otros costos'
        },
        operations:{
            unwanted_products: {
                description: "Producto incorrecto",
                recomendation: "Te recordamos que puedes duplicar el pedido con incidencia y ajustar los productos a reenviar para hacerle llegar a tu cliente los productos pendientes.",
            },
            missing_products: {
                description: "Producto faltante",
                recomendation: "Te recordamos que puedes duplicar el pedido con incidencia y ajustar los productos a reenviar para hacérselos llegar a tu cliente y brindar una mejor experiencia.",
            },
            wrong_order: {
                description: "Guía cruzada",
                recomendation: "Te recordamos que puedes duplicar el pedido con incidencia para hacerle llegar a tu cliente los productos que ordenó y brindar una mejor experiencia.",
            }, 
            operations_others:{
                description: "Otros - Operaciones",
                recomendation: "",
            },
            damaged_product:{
                description: "Dañado",
                recomendation: "Te recordamos que puedes duplicar el pedido con incidencia para hacerle llegar a tu cliente los productos que ordenó y brindar una mejor experiencia.",
            }

        },
        commercial:{
            incorrect_charge:{
                description: "Mal cobrado",
                recomendation: "",
            },
            other:{
                description: "Otro - Área Comercial",
                recomendation: "",

            }
        },
        client:{
            origin_error:{
                description: "Error de origen",
                recomendation: "",

            }
        },
        delivery:{
            package_lost:{
                description: "Perdido",
                recomendation: "Te recordamos que puedes duplicar el pedido con incidencia para hacerle llegar a tu cliente los productos que ordenó y brindar una mejor experiencia.",

            }, 
            damaged_product:{
                description: "Dañado",
                recomendation: "Te recordamos que puedes duplicar el pedido con incidencia y ajustar los productos a reenviar para hacérselos llegar a tu cliente y brindar una mejor experiencia.",

            }, 
            delivery_delay:{
                description: "Demora en entrega",
                recomendation: "Te recordamos que puedes duplicar el pedido con incidencia para hacerle llegar sus productos a tu cliente.",

            }, 
            false_delivery:{
                description: "Entrega Incorrecta",
                recomendation: "Te recordamos que puedes duplicar el pedido para hacerle llegar sus productos a tu cliente e iniciar el retorno del pedido con incidencia para retornarlo al almacén en caso de ser recuperado.",

            }, 
            package_stolen:{
                description: "Robo",
                recomendation: "Te recordamos que puedes duplicar el pedido con incidencia para hacerle llegar a tu cliente los productos que ordenó y brindar una mejor experiencia.",

            },
            delivery_others:{
                description: "Otros - Paquetería",
                recomendation: "",

            },
            missing_products: {
                description: "Producto faltante",
                recomendation: "Te recordamos que puedes duplicar el pedido con incidencia y ajustar los productos a reenviar para hacérselos llegar a tu cliente y brindar una mejor experiencia.",
            },
        },
        html:{
            rejected:{
                message_initial: "Hemos tenido que rechazar la disputa del pedido",
                category: "categorizada como",
                apologies: "lamentamos el inconveniente.",
                reazon: "El motivo del rechazo se debe a que",
                evidence: "Adjunto a este correo te compartimos la eviencia que respalda esta decisión.",
                recommendation: "Para brindar la mejor experiencia posible a tu cliente, te recomendamos",
                contact: "Puedes contactarnos desde el chat en la plataforma si requieres más detalles.",
                farewell:"Será un placer atenderte",
                sender:"Cubbo"
            },
            accepted:{
                message_initial: "Hemos aceptado la disputa del pedido",
                category: "categorizada como",
                apologies: "lamentamos el inconveniente.",
                resolution: "El monto a reembolsar lo verás reflejado en tu próxima factura como crédito a favor. Estos son los conceptos considerados para el reembolso:",
                contact: "Puedes contactarnos desde el chat en la plataforma si requieres más detalles.",
                farewell:"Será un placer atenderte",
                sender:"Cubbo"
            }
        },

        status:{
            accepted: "Aceptado",
            rejected: "Rechazado"
        },
        messages:{
            none : "Ninguna",
            without_reason: "Sin motivo",
            success_message: "La disputa fue resuelta correctamente",
            success_update_message: "La disputa fue actualizada.",
            error_message: "Error al guardar disputa",
        }
    },

    formatted_relative_time: {
        today: "Hoy",
        yesterday: "Ayer",
        tomorrow: "Mañana",
        date: "{{day}} {{month}}",
        from_now: "Hace {{days}} días",
    },

    orders: {
        filters: {
            order_number: "Número de pedido",
            paste_order_numbers: "Pega el número de los pedidos",
            paste_shipping_numbers: "Pega el número de las guías",
            shipping_number: "Número de guía",
            channel_name: "Canal de venta",
            shipping_method: "Método de envío",
            status: "Estado del pedido",
            shipping_status: "Estado de envío",
            shipping_date: "Fecha de envío",
            channel_name_placeholder: "Selecciona un canal de venta",
            shipping_method_placeholder: "Selecciona un método de envío",
            shipping_status_placeholder: "Selecciona un estado de envío",
            shipping_incident_category_placeholder: "Cualquier tipo",
            shipping_incident_status_placeholder: "Cualquier estado",
            status_placeholder: "Selecciona un estado",
            multifilter_bulk: "Búsqueda en bulk order o tracking",
            store: "Tienda",
            is_delayed: "Demorados",
            first_delivery_on_time: "Intento de entrega a tiempo",
            failed_delivery: "Entrega fallida",
            shipping_incident: "Con incidente",
            without_shipping_incident: "Sin incidente",
            unsolved_shipping_incident: "Incidente sin resolver",
        },
        incidents_bar: {
            select_action: "Selecciona una acción",
            deselect_all_orders: "Deseleccionar todos los pedidos",
            orders_selected: "Pedidos seleccionados",
            update_incident: "Actualizar estado de incidente",
            update_shipping_status: "Actualizar estado de envío",
            orders: "Pedidos",
            update_current_status_for: "Actualizar estado actual por",
            update_current_shipping_status_for: "Actualizar estado de envío actual por",
            create_incident: "Crear incidente por",
            dialog: {
                create_title: "¿Estás seguro de que deseas crear incidente por",
                for: "para",
                orders_selected: "pedidos seleccionados?",
                errors_intro: 'Pedidos para os quais não é possível criar este incidente'
            }
        },
        incidents_container: {
            previous_delivery_date: "Fecha de entrega anterior",
            new_address: "Nueva dirección de entrega",
            address: "Dirección ",
            number: "Número ",
            neighborhood: "Barrio ",
            city: "Ciudad ",
            zip_code: "CP ",
            state: "Estado "
        },
        list: {
            created_at: "Creado el",
            create_incident: "Crear incidente",
            resolve_incident: "Resolver incidente",
            update_status_incident: "Actualizar estado de incidente",
            update_shipping_status: "Actualizar estatus de Envio",
            eta: "Entrega estimada",
            at: "El",
            sure_to_report:" ¿Estás seguro de que deseas crear un incidente por ",
            requiring_monitoring: "Requieren monitoreo",
            no_information: "Sin estado de envío",
            no_pieces: "Sin productos a enviar",
        },
        shipping_incident : {
            incident: "incidente",
            status : {
                PENDING: "Pendiente",
                IN_REVIEW: "En revisión",
                RESOLVED: "Resuelto",
                NO_INCIDENT: "Sin incidente"
            },
            category : {
                CANCELED: "Cancelación solicitada",
                DELAYED: "Demora de entrega",
                MISSING_REFERENCES: "Complementar dirección",
                DAMAGED: 'Producto dañado',
                MISSING_PRODUCT: 'Faltan productos',
                FALSE_DELIVERY: 'Entrega incorrecta',
                CHANGE_ADDRESS: "Cambiar dirección",
                RESCHEDULE: "Reprogramar fecha de entrega",
            },
            resolution: {
                STOLEN: ' Pedido robado',
                LOST: ' Pedido extraviado',
                NON_REFUNDABLE_DAMAGED: 'Pedido dañado no reembolsable',
                RETURNED: ' Retornó al almacén',
                REENTERED: "reentered",
                DELIVERED: ' Pedido entregado',
                REFUNDABLE: ' Reembolsable'
            },
            cant_update: 'Ninguno de los pedidos seleccionados tienen incidentes creados por lo que no hay elementos que actualizar',
            cant_create:{
                order_returned: 'El pedido ya ha sido devuelto',
                order_not_shipped_or_returning: 'El pedido no ha sido enviado o está en proceso de devolución',
                unresolved_same_category_incident: 'El pedido ya tiene un incidente de este tipo sin resolver',
                only_damaged_missing_product_false_delivery_incidents_allowed: 'Sólo se permiten incidentes de tipo dañado, faltan productos o entrega incorrecta',
                only_delayed_canceled_missing_references_incidents_allowed: 'Sólo se permiten incidentes de tipo demora de entrega, cancelación solicitada o complementar dirección',
                remove_stock_order: 'No se puede crear un incidente para un pedido de stock',
                temporarily_disabled: 'Estamos trabajando en esto, por favor seguir usando el form'
            },
            errors: {
                codes: {
                    INVALID_CATEGORY_FOR_ORDER_STATUS: 'La categoría de incidente no es válida para el estado del pedido',
                    ORDER_ALREADY_RETURNED: 'El pedido ya ha sido devuelto',
                    ORDER_NOT_SHIPPED: 'El pedido no ha sido enviado',
                    UNRESOLVED_INCIDENT: 'El pedido ya tiene un incidente de este tipo sin resolver',
                    DESCRIPTION_REQUIRED: 'La descripción del incidente es requerida',
                    INCIDENT_ALREADY_RESOLVED: 'El incidente ya ha sido resuelto',
                    INCOMPATIBLE_ORDER: 'Este tipo de pedido no puede crear incidencias',
                    ORDER_HAS_RETURN:'El Pedido ya ha sido devuelto o regresando.',
                    ORDER_HAS_DELIVERY:'El pedido ya ha sido entregado.',
                    NO_INCIDENT_CREATED: "El pedido no tiene un incidente creado."

                },
                error_modal: {
                    confirmations: {
                        CREATE_INCIDENT: '¿Estás seguro de que deseas crear incidentes de tipo {incident_type} para los {order_count} pedidos seleccionados?',
                        RESOLVE_INCIDENT: '¿Estás seguro de que deseas resolver incidentes para los {order_count} pedidos seleccionados?',
                        UPDATE_STATUS_INCIDENT: '¿Estás seguro de que deseas actualizar estado {status} para los incidentes de {order_count} pedidos seleccionados?',
                        UPDATE_SHIPPING_STATUS: '¿Estás seguro de que deseas actualizar el estatus de envio {shipping_status} para los {order_count} pedidos seleccionados?',
                        omit_errors_and_submit: 'Omitir y aceptar el resto',
                        no_valid_elements: 'No hay pedidos válidos para esta acción',
                    },
                    messages: {
                        action_not_possible: 'Para {quantity_errors} de {quantity} pedidos selecionados, no será posible realizar esta acción',
                        omit_errors_and_submit_question: '¿Quieres omitir estos pedidos y aceptar el resto de la selección?',
                        order_labels_with_errors: 'Guías de los pedidos con errores',
                        no_valid_elements_tooltip: 'No es posible realizar esta acción para ninguno de los pedidos seleccionados',
                    }
                }
            },
        },

        show_shipping_incidents: {
            from_date: ". El ",
            created_by: {
                RECIPIENT: "Creado por el comprador final ",
                CUBBO: "Creado por Cubbo ",
            },
            info_types:{
                important: "Importante: "
            },
            toggle_description: {
                hide: "Ocultar ",
                show: "Ver "
            },
            description_title: {
                MISSING_REFERENCES: "dirección compartida",
                OTHER:  "evidencia compartida",
            },
            categories:{
                DELAYED: "Incidente: Demora de entrega",
                MISSING_REFERENCES: "Incidente: Complementar dirección",
                CANCELED: "Incidente: Cancelar entrega",
                DAMAGED: "Incidente: Producto dañado",
                MISSING_PRODUCT: "Incidente: Faltan productos",
                FALSE_DELIVERY: "Incidente: Entrega incorrecta",
                CHANGE_ADDRESS: "Incidente: Cambiar dirección",
                RESCHEDULE: "Incidente: Reprogramar fecha de entrega",
            },
            status: {
                PENDING: "Pendiente",
                IN_REVIEW: "En revisión",
                RESOLVED: "Resuelto"
            },

            evidence_type:{
                product_images:"Fotos a detalle del incidente.",
                label_images:"Fotos de etiqueta de envío.",
                package_images:"Fotos del paquete."
            }
        },
        pod:{
            download: "Descargar",
            received_by: "Recibido por",
            title: "Evidencia de entrega",
            not_provided: "Esta paquetería no recolecta evidencias en la entrega",
            unavailable: "No tenemos evidencia de entrega para esta orden",
            proof: "Comprobante",
            download_success: "Se ha descargado el comprobante de entrega",
            download_error: "Ocurrió un error al descargar, por favor inténtalo de nuevo más tarde",
        },
        packing_material: {
            BUBBLE_WRAP: "Plástico burbuja",
            KRAFT_PAPER: "Papel Kraft",
            CORN_EXTRUDATE: "Extruido de maíz"
        }
    },

    cubbo_lastmile_events: {
        order_events: "Historial",
        created_email: "Por {{user_email}}",
        created_email_xdock: "Por {{user_email}} en {{xdock_name}}",
        no_events: "No existen eventos disponibles",
        errors: "Conflicto registrado: {{error_codes}}",
        is_collapsed: "Ver todo",
        is_not_collapsed: "Contraer",
        event_types: {
            CREATED_LABEL: "Guía generada",
            RECEIVED_AT_XDOCK: "Recibido en Xdock",
            CREATED_DELIVERY_ROUTE: "Ruta de entrega generada",
            GROUPED_PACKAGES: "Paquetes agrupados",
            DISPATCHED_TO_MIDDLE_MILE: "Despachado a Middle Mile",
            MIDDLE_MILE_IN_TRANSIT: "En transito por Middle Mile",
            DISPATCHED_FOR_DELIVERY: "Despachado a driver",
            IN_DELIVERY: "En ruta de entrega",
            DELIVERED: "Entregado",
            SHIPPING_INCIDENT: "Incidente",
            FAILED_DELIVERY_ATTEMPT: "Intento de entrega fallido",
            RESCHEDULED_DELIVERY: "Entrega reprogramada",
            CANCELLED: "Cancelado",
            RETURNING: "Retornando",
            DISPATCHED_TO_XDOCK: "Despachado a Xdock",
            RETURNED: "Retornado",
            LOST: "Paquete reportado como perdido",
            DAMAGED: "Paquete reportado como dañado",
            STOLEN: "Paquete reportado como robado",
            RETURN_DUE_TO_FAILED_DELIVERY_ATTEMPT: "Retornado debido a intento de entrega fallido",
            DISPATCHED_TO_XDOCK_FOR_RETURN: "Despachado a Xdock por retorno",
            RECEIVED_AT_XDOCK_FOR_RETURN: "Recibido en Xdock por retorno",
            DISPATCHED_TO_ORIGIN_WAREHOUSE: "Despachado al almacén de origen",
        },
        error_codes: {
            LABEL_NOT_FOUND: "Guía no encontrada",
            LABEL_WRONG_CARRIER: "Paquetería incorrecta",
            LABEL_INVALID_STATUS: "Guía con status incorrecto",
            WRONG_ROUTE: "Ruta incorrecta",
            CANCELLED: "Pedido cancelado",
            PROCESS_REVERSE_LOGISTICS: "Procesando logística inversa",
            REPROGRAMMED: "Entrega reprogramada",
            WRONG_STATUS: "Status incorrecto",
        }
    },

    returns: {
        unidentified: "No identificado",
        errors: {
            not_belong_to_warehouse: "El retorno de la order #{{order_number}} pertenece al almacén '{{warehouse_name}}', !SEPARALO¡ para procesarlo en el almacén correcto.",
        }
    },

    generic: {
        order: "Pedido",
        orders: "Pedidos",
        error: "Error",
        store: "Tienda",
        warehouse: "Almacén",
        country: "País",
        status: "Estado",
        from: "Desde",
        to: "Hasta",
        usages: "Usos",
        billing_statements: "Cortes",
        empty_table: "No hay elementos para mostrar",
        undefined: "No definido",
        active: "Activo",
        pending: "Pendiente",
        disabled: "Desactivado",
        not_having_current_billing_configuration: "No configurados",
        having_current_billing_configuration: "Configurados",
        store: "Tienda",
        stores: "Tiendas",
        store_name: "Nombre de la tienda",
    },
    

    access_denied: "Lo sentimos, no tienes permisos para acceder a esta página",
        
    filter: {
        button: "Filtrar",
        reset: "Limpiar filtros",
        apply: "Aplicar filtros",
        select: "Selecciona un estado",
        from_to: "de-hasta",
        export: "Exportar",
        in: 'en'
    },

    dialog : {
        yes: "Si",
        no: 'No'
    },

    statuses: {
        order_statuses: {
            unknown: "Sin status",
            initial: "Sincronizando",
            empty: "Pedido vacío",
            pending: "Pendiente",
            processing: "Procesando",
            shipped: "Enviado",
            returning: "Retornando a Cubbo",
            returned: "Recibiendo retorno",
            canceled: "Cancelado",
            error: "Falta Información",
            entirely_dropshipping: "Fulfilleado Externamente",
            backorder: "Sin Stock",
            unpaid: "Sin pagar",
            cancelation_window: "En Ventana de Cancelación",
            hold: "En pausa",
            interrupted: "Interrumpido",
            waiting_pick_up: "Esperando recolección",
            awaiting_pick_up: "Pendiente de recolectar",
            picked_up: "Recolectado",
            in_warehouse: "Recibido en almacén",
            return_arrived: "Retornó a Cubbo",
            reentered: "Retorno reingresado",
            delayed: "Demorado"
        },
        tracking_statuses: {
            unknown: "Sin status",
            in_transit: "En tránsito",
            shipment_in_transit:  "En tránsito",
            shipment_delivered: "Entregado",
            shipment_with_incident: 'Incidente',
            shipment_canceled: 'Entrega Cancelada',
            shipment_to_be_self_collected: 'Recolectar en oficina',
            shipment_out_for_delivery: 'En ruta de entrega',
            shipment_with_incident_lost: 'Incidente: Extravío',
            shipment_with_incident_stolen: 'Incidente: Robado',
            shipment_with_incident_damaged: 'Incidente: Dañado',
            delayed: 'Demorado',
            shipment_returned: "Retornado por paquetería"
        },
        tracking_descriptions: {
            second_try: 'Segundo intento',
            third_try: 'Tercer intento',
            client_requested: 'Solicitud del comprador',
            missing_references: 'Faltan referencias',
            not_at_home: 'El comprador estaba ausente',
            refused_payment: 'El comprador se rehusó a pagar',
            refused_package: 'El comprador rechazó el paquete',
            cubbo_requested: 'Solicitud de Cubbo',
            carrier_requested: 'Por paquetería',
            stolen: 'Robo',
            lost: 'Pérdida',
            damaged: 'Dañado',
            returned_by_carrier: 'Por entrega no exitosa',
            returned_by_client: 'Por devolución solicitada',
            in_return: "En retorno por paquetería",
        },
        returns_statuses: {
            unknow: "Sin status",
            requested: "Esperando retorno",
            pending: "Decisión Pendiente",
            cancelled: "Reenvío Cancelado",
            ordered: "Reenvío ordernado",
            received: "Recibiendo retorno",
            arrived_at_warehouse: "Llegó al almacén",
            reentered: "Retorno reingresado",
        },

    },
    billing: {
        create_error_title: "Ocurrió un error, inténtalo más tarde",
        create_invalid_content_error_title: "Completa la información requerida y/o corrígela para poder crear la configuración de cobro",
        from_prefix: "Más de ",
        title: "Cobranza",
        continue: "Continuar",
        save: "Terminar y guardar configuración",
        create: "Crear configuración",
        disable: "Desactivar",
        duplicate: "Duplicar",
        disable_title: "Desactivar billing al corte",
        billing_configurations_history: "Historial de configuraciones de cobro",
        billing_custom_services: "Cobros/Descuentos adicionales",
        disable_billing_for_store_success: "Se desactivará la configuración de cobro en el próximo corte",
        status: {
            ACTIVE: "Activo",
            DISABLED: "Desactivado",
            UNREQUIRED: "No requerido",
            PENDING: "Pendiente"
        },
        stores: {
            start_date: "Inicio de vigencia",
            end_date: "Siguiente corte",
            empty: "No hay Tiendas que mostrar.",
            about: "Sobre la tienda",
            no_data: "Sin información",
            configure: "Configurar tarifas de cobro",
            next_cutoff_at: "Fecha de corte",
            due_next_week: "Vence la próxima semana",
        },
        save_dialog: {
            title: "Crear configuración de cobro",
            description: "Confirma la creación de la configuración de cobro",
            confirm_button: "Confirmar",
            cancel_button: "Cancelar"
        },
        confirm_disable_dialog: {
            title: "Desactivar en el próximo corte",
            description: "Confirma la desactivación de la configuración de cobro en el próximo corte",
            confirm: "Confirmar",
            cancel: "Cancelar"
        },
        confirm_exit_dialog: {
            title: "Se descartarán los cambios ¿Deseas continuar?"
        },
        tabTitles: {
            billing_date: "Fecha de facturación",
            storage: "Almacenamiento",
            pick_and_pack: "Pick and Pack",
            shipping_label: "Envíos nacionales",
            international_shipping_label: "Envíos internacionales",
            replenishment: "Recibo de inventario",
            returns: "Retornos",
            work_orders: "Maquilas",
            difal: "Difal",
            shipping_insurance: "Seguro de envío",
            packing_material: "Materiales de empaque"
        },
        ongoing: "En curso",
        billed: "Anterior",
        future: "Futura",
        duplicate_configuration: "Duplicar configuración de cobro",
        show_configuration: "Ver configuración de cobro",
        store_billing_config: {
            cutoff_interval: {
                title: "Frecuencia del corte",
                options: {
                    YEAR: "Anual",
                    MONTH: "Mensual",
                    WEEK: "Semanal"
                }
            }
        },
        range_billing_price_config_table: {
            errors:{
                MISSING_FROM_VALUE: "Ingresa un valor",
                MISSING_TO_VALUE: "Ingresa un valor",
                MISSING_PRICE_VALUE: "Ingresa un precio",
                MISSING_UNIT_VALUE: "Selecciona una unidad",
                FROM_IS_GREATER_THAN_TO: "'Desde' debe ser menor que 'Hasta'",
                FROM_AND_TO_ARE_EQUAL: "'Desde' debe ser menor que 'Hasta'",
                EXCESS_IS_ONLY_ALLOWED_AT_END: "'Exceso' solo puede ser el último elemento",
                MISSING_PRICE_MODE: "",
                INVALID_UNIT_VALUE: "Selecciona una unidad"
            },
            unit_options: {
                PER_UNIT: "Cargo por pieza",
                TOTAL: "Cargo por total",
                EXCESS_PER_UNIT: "Cargo extra por pieza excedida",
                EXCESS_TOTAL: "Cargo extra fijo por exceso"
            },
            last_row_placeholder: "Sin límite"
        },
        table_headers: {
            from_piece: "Desde pieza",
            to_piece: "Hasta pieza",
            from_weight: "Desde kg",
            to_weight: "Hasta kg",
            price: "Precio",
            percentage: "Porcentaje",
            unit: "Unidad del cargo",
            package: "Paquete",
            packing_material: "Material de empaque",
            location_type: "Tipo de ubicación",
            tariff: "Tarifa por ubicación",
            NORTH_AMERICA: "Precio Norte América",
            LATIN_AMERICA: "Precio América Latina",
            CARIBBEAN: "Precio Caribe",
            EUROPE: "Precio Europa",
            ASIA: "Precio Asia",
            AFRICA: "Precio África"
        },
        pick_and_pack_services: {
            picking_title: "Picking",
            insert_title: "Por injerto añadido",
            serial_number_title: "Control de salida por número de serie",
            fragile_title: "Embalaje de paquete frágil",
            packages_title: "Empaques",
            package_title:{
                parcel_bag: "Bolsa de paquetería",
                jiffy_envelope: "Sobre Jiffy",
                cubbo_box: "Caja Cubbo",
                customer_box: "Caja cliente"
            },
            picking_unit_options: {
                PER_UNIT: "Cargo por pieza",
                TOTAL: "Cargo por pedido",
                EXCESS_PER_UNIT: "Cargo extra por pieza excedida",
                EXCESS_TOTAL: "Cargo extra fijo por exceso"
            },
            package_unit_options: {
                TOTAL: "Cargo por paquete",
            },
            fragile_picking_unit_options: {
                TOTAL: "Cargo por paquete",
            }
        },
        billing_storage_table: {
            STORAGE_BY_LOCATION: "Cargo por ubicación",
            STORAGE_BY_PRODUCT: "Cargo por pieza almacenada",
            missing_picking_standard_error: "ERROR: Ubicación PICKING STANDARD inexistente",
            category_codes:{
                PICKING_STANDARD: "Regular",
                LOST: "Lost",
                DAMAGED: "Dañados",
                PALLET: "Pallet",
                RECEPTION: "Recepción",
                PRESALE: "Preventa",
                RELOCATION: "Reubicación",
                OFF_PREMISES: "Off premises",
                PICKING_MINIBIN: "Picking minibin"
            },
            calculated_tariff_tooltip: "Monto calculado con base en el precio definido de la ubicación regular",
            tariff_error_message: "Ingresa la tarifa",
            units: "ubicación/mes"
        },
        billing_stroage_table_by_unit: {
            unit_options: {
                PER_UNIT: "Cargo por pieza almacenada",
            },
        },
        replenishment_services: {
            replenishment_title: "Recibo de piezas regulares",
            replenishment_lots_title: "Recibo de piezas con control de lote",
            unit_options: {
                PER_UNIT: "Cargo por pieza recibida",
                TOTAL: "Cargo por recibo",
                EXCESS_PER_UNIT: "Cargo extra por pieza excedida",
                EXCESS_TOTAL: "Cargo extra fijo por exceso"
            },
        },
        difal_services: {
            difal_title: "Difal",
            unit_options: {
                TOTAL: "Cargo por pedido"
            },
        },
        shipping_insurance_services: {
            shipping_insurance_title: "Seguro de envío",
            unit_options: {
                TOTAL: "Cargo por pedido"
            },
        },
        packing_material_services: {
            packing_material_title: "Materiales de empaque",
            packing_material_codes: {
                BUBBLE_WRAP: "Plástico burbuja",
                CORN_EXTRUDATE: "Extruido de maíz",
                KRAFT_PAPER: "Papel Kraft"
            },
            unit_options: {
                TOTAL: "Cargo por guía"
            },
        },
        return_services: {
            return_order_title: "Recibo de piezas regulares",
            return_order_lots_title: "Recibo de piezas con control de lote",
            return_shipping_label_title: "Guías de retorno",
            unit_options: {
                PER_UNIT: "Cargo por pieza recibida",
                TOTAL: "Cargo por recibo",
                EXCESS_PER_UNIT: "Cargo extra por pieza excedida",
                EXCESS_TOTAL: "Cargo extra fijo por exceso"
            },
            return_label_unit_options: {
                TOTAL: "Cargo por guía",
                EXCESS_PER_UNIT: "Cargo extra por kilo excedido",
            },
        },
        work_orders_services: {
            work_order_title: "Maquilas",
            unit_options: {
                PER_UNIT: "Cargo de persona por día"
            }
        },
        national_shipping_label_services: {
            titles:{
                SUPER_SAVER: "Super saver",
                LOCAL: "Local",
                PREMIUM: "Premium",
                ECONOMY: "Economy",
                PICKUP: "Recolección en almacén",
                SP_CAP: "SP Capital",
                SP_INT: "SP Interior",
                RJ_CAP: "RJ Capital",
                RJ_INT: "RJ Interior",
                ES_CAP: "ES Capital",
                ES_INT: "ES Interior",
                MG_CAP: "MG Capital",
                MG_INT: "MG Interior",
                BA_CAP: "BA Capital",
                BA_INT: "BA Interior",
                SE_CAP: "SE Capital",
                SE_INT: "SE Interior",
                PE_CAP: "PE Capital",
                PE_INT: "PE Interior",
                AL_CAP: "AL Capital",
                AL_INT: "AL Interior",
                PB_CAP: "PB Capital",
                PB_INT: "PB Interior",
                RN_CAP: "RN Capital",
                RN_INT: "RN Interior",
                CE_CAP: "CE Capital",
                CE_INT: "CE Interior",
                PI_CAP: "PI Capital",
                PI_INT: "PI Interior",
                MA_CAP: "MA Capital",
                MA_INT: "MA Interior",
                PA_CAP: "PA Capital",
                PA_INT: "PA Interior",
                AP_CAP: "AP Capital",
                AP_INT: "AP Interior",
                AM_CAP: "AM Capital",
                AM_INT: "AM Interior",
                RR_CAP: "RR Capital",
                RR_INT: "RR Interior",
                AM_INT_2: "AM Interior 2",
                AC_CAP: "AC Capital",
                AC_INT: "AC Interior",
                DF_CAP: "DF Capital",
                DF_INT: "DF Interior",
                GO_CAP: "GO Capital",
                DF_INT_2: "DF Interior 2",
                GO_CAP_2: "GO Capital 2",
                GO_INT: "GO Interior",
                RO_CAP: "RO Capital",
                RO_INT: "RO Interior",
                TO_CAP: "TO Capital",
                TO_INT: "TO Interior",
                MT_CAP: "MT Capital",
                MT_INT: "MT Interior",
                RO_INT_2: "RO Interior 2",
                RO_INT_3: "RO Interior 3",
                MS_CAP: "MS Capital",
                MS_INT: "MS Interior",
                PR_CAP: "PR Capital",
                PR_INT: "PR Interior",
                SC_CAP: "SC Capital",
                SC_INT: "SC Interior",
                RS_CAP: "RS Capital",
                RS_INT: "RS Interior"
            },
            extended_zone_titles:{
                SUPER_SAVER: "Zona extendida Super saver",
                LOCAL: "Zona extendida Local",
                PREMIUM: "Zona extendida Premium",
                ECONOMY: "Zona extendida Economy",
                SP_CAP: "Zona extendida SP Capital",
                SP_INT: "Zona extendida SP Interior",
                RJ_CAP: "Zona extendida RJ Capital",
                RJ_INT: "Zona extendida RJ Interior",
                ES_CAP: "Zona extendida ES Capital",
                ES_INT: "Zona extendida ES Interior",
                MG_CAP: "Zona extendida MG Capital",
                MG_INT: "Zona extendida MG Interior",
                BA_CAP: "Zona extendida BA Capital",
                BA_INT: "Zona extendida BA Interior",
                SE_CAP: "Zona extendida SE Capital",
                SE_INT: "Zona extendida SE Interior",
                PE_CAP: "Zona extendida PE Capital",
                PE_INT: "Zona extendida PE Interior",
                AL_CAP: "Zona extendida AL Capital",
                AL_INT: "Zona extendida AL Interior",
                PB_CAP: "Zona extendida PB Capital",
                PB_INT: "Zona extendida PB Interior",
                RN_CAP: "Zona extendida RN Capital",
                RN_INT: "Zona extendida RN Interior",
                CE_CAP: "Zona extendida CE Capital",
                CE_INT: "Zona extendida CE Interior",
                PI_CAP: "Zona extendida PI Capital",
                PI_INT: "Zona extendida PI Interior",
                MA_CAP: "Zona extendida MA Capital",
                MA_INT: "Zona extendida MA Interior",
                PA_CAP: "Zona extendida PA Capital",
                PA_INT: "Zona extendida PA Interior",
                AP_CAP: "Zona extendida AP Capital",
                AP_INT: "Zona extendida AP Interior",
                AM_CAP: "Zona extendida AM Capital",
                AM_INT: "Zona extendida AM Interior",
                AM_INT_2: "Zona extendida AM Interior 2",
                RR_CAP: "Zona extendida RR Capital",
                RR_INT: "Zona extendida RR Interior",
                AC_CAP: "Zona extendida AC Capital",
                AC_INT: "Zona extendida AC Interior",
                DF_CAP: "Zona extendida DF Capital",
                DF_INT: "Zona extendida DF Interior",
                DF_INT_2: "Zona extendida DF Interior 2",
                GO_CAP: "Zona extendida GO Capital",
                GO_CAP_2: "Zona extendida GO Capital 2",
                GO_INT: "Zona extendida GO Interior",
                RO_CAP: "Zona extendida RO Capital",
                RO_INT: "Zona extendida RO Interior",
                RO_INT_2: "Zona extendida RO Interior 2",
                RO_INT_3: "Zona extendida RO Interior 3",
                TO_CAP: "Zona extendida TO Capital",
                TO_INT: "Zona extendida TO Interior",
                MT_CAP: "Zona extendida MT Capital",
                MT_INT: "Zona extendida MT Interior",
                MS_CAP: "Zona extendida MS Capital",
                MS_INT: "Zona extendida MS Interior",
                PR_CAP: "Zona extendida PR Capital",
                PR_INT: "Zona extendida PR Interior",
                SC_CAP: "Zona extendida SC Capital",
                SC_INT: "Zona extendida SC Interior",
                RS_CAP: "Zona extendida RS Capital",
                RS_INT: "Zona extendida RS Interior"
            },
            unit_options: {
                TOTAL: "Cargo por guía",
                EXCESS_PER_UNIT: "Cargo extra por kilo excedido",
            },
            pickup_unit_options: {
                TOTAL: "Cargo por pedido",
            }
        },
        international_shipping_label_services: {
            unit_options: {
                TOTAL: "Cargo por guía",
                EXCESS_PER_UNIT: "Cargo extra por kilo excedido"
            }
        },
        custom_services: {
            create_current_title: "Crear cobro/descuento adicional para el corte actual",
            create_button: "Crear cobro/descuento adicional",
            types: {
                discount: "Descuento",
                charge: "Cargo"
            },
            no_services: "Sin información",
            create_custom_service: {
                init_error: "Ocurrió un error al inicializar, inténtalo más tarde",
                create_error: "Ocurrió un error durante la creación, inténtalo más tarde",
                store_name: "Tienda: ",
                warehouse_name: "Almacén: ",
                is_discount: "¿Es descuento?",
                title: "Título",
                title_placeholder: "Ingresa un título",
                description: "Descripción",
                description_placeholder: "Ingresa una descripción",
                amount_discount: "Monto de descuento",
                amount_placeholder_discount: "Ingresa un monto de descuento",
                amount_charge: "Monto de cobro",
                amount_placeholder_charge: "Ingresa un monto de cobro",
                no_amount: "El monto no puede ser 0",
                create_button: "Crear servicio personalizado",
                remaining_text_length: "Caracteres disponibles restantes: ",
                confirm_create_dialog:{
                    title_DISCOUNT: "Crear descuento adicional",
                    title_CHARGE: "Crear cobro adicional",
                    description_DISCOUNT: "Confirma la creación del descuento adicional para la tienda {STORE_NAME} en almacén {WAREHOUSE_NAME}",
                    description_CHARGE: "Confirma la creación del cobro adicional para la tienda {STORE_NAME} en almacén {WAREHOUSE_NAME}",
                    confirm_button: "Confirmar",
                    cancel_button: "Cancelar"
                }
            },
            list_custom_services: {
                table_columns: {
                    id: "ID",
                    billed_total_price: "Monto",
                    type: "Tipo",
                    title: "Título",
                    description: "Descripción"
                },
                init_error: "Ocurrió un error al inicializar, inténtalo más tarde",
                table_filters: {
                    title: "Título",
                    title_placeholder: "Busca por título",
                    billed_total_price: "Monto",
                    greater_or_equal_than_billed_total_price: "Mayor o igual que monto",
                    less_or_equal_than_billed_total_price: "Menor o igual que monto",
                    is_discount: "Es descuento",
                    is_charge: "Es cargo",
                    description: "Descripción"
                }
            }
        }
    },
    work_orders: {
        work_order_details: {
            work_orders: "Maquilas",
            work_order_details: "Detalles de solicitud de maquila",
            work_order_number: "Nº de maquila",
            solicitant: "Solicitante",
            work_order_status: "Estado de maquila",
            on_date: "El",
            comments_to_the_brand: "Comentarios para la marca",
            next_status: "Al guardar los datos, se actualizará el estado de la maquila a",
            processing_work_order: "Procesando maquila",
            product_origin: "Origen del material requerido",
            rem_order: "Retiro de inventario",
            no_rem_order: "Esta maquila no tiene pedido de retiro de inventario",
            work_order: "Solicitud de maquila",
            work_order_type: "Tipo de proceso",
            notes: "Notas",
            instruction: "Instrucción",
            activity: "Actividad",
            replenishment: "Ingreso de producto maquilado",
            replenishment_id: "Recibo de inventario de producto maquilado",
            replenishment_note: "Desde este recibo de inventario podrás ver el progreso del ingreso a tu inventario del producto maquilado",
            resolve_order: "Resolver solicitud",
            notification_message: {
                success_update_message: "Estado de maquila actualizado correctamente",
                error_update_message: "Error al actualizar estado de maquila"
            },
            work_order_statuses: {
                REQUESTED: "Solicitado",
                IN_VALIDATION: "En validación",
                SCHEDULED: "Próxima a iniciar",
                IN_PROGRESS: "En progreso",
                COMPLETED: "Completada",
                REJECTED: "Rechazada",
                CANCELED: "Cancelada"
            },
            work_order_types: {
                LABELING_PLASTISEAL: "De etiquetado o plastifechado",
                PRODUCT_VALIDATION: "De validación de producto",
                KIT_ASSEMBLY_SHRINKWRAP: "De armado de kits y/o aplicación de termoencogido",
                PACKAGING: "De empaquetado",
                PRODUCT_SEGREGATION: "De segregación de producto"
            },
            work_order_product_origin: {
                ALL_INVENTORY_AT_WAREHOUSE: "Todo el material se encuentra en mi inventario almacenado en Cubbo disponible para vender",
                MATERIAL_IN_TRANSIT_TO_WAREHOUSE: "El material va en camino al almacén",
                PARTIAL_MATERIAL_INVENTORY_IN_TRANSIT_TO_WAREHOUSE: "Parte del material se encuentra almacenado en Cubbo y otra parte va en camino al almacén"
            },
            work_order_card: {
                update_work_order_status: "Pasar maquila a estatus",
                save_button: "Guardar actualización",
                start_progress_button: "Iniciar progreso",
                complete_work_order_button: "Completar maquila",
                reject_work_order_button: "Rechazar maquila",
                complete_the_information_requested: "Completa la información cuando se desee comenzar la validación operativa de la maquila",
                validate_order: "La solicitud cuenta con todo lo necesario para pasar a validación operativa?",
                confirm_validation: "Sí, aceptar solicitud",
                reject_validation: "No, rechazar solicitud",
                complete_the_information_in_validation: "Completa la información una vez que se haya concluido la validación de la solicitud de maquila",
                necessary_pieces: "Se cuentan con todos las piezas necesarias para realizar la maquila?",
                estimated_operator_quantity: "Cuántas personas se estima se asignarán para procesar la maquila?",
                estimated_labor_days_quantity: "Días estimados de procesamiento",
                scheduled_order: "Esta solicitúd ha sido colocada cómo próxima a iniciar",
                complete_the_information_in_progress: "Completa la información una vez que haya concluido la maquila",
                confirm_operator_quantity: "Confirma el total de personas utilizadas en la maquila",
                estimated_labor_days_quantity: "Confirma los días estimadas de procesamiento",
                completed_work_order: "Esta maquila ha sido completada",
                rejected_work_order: "Esta maquila ha sido rechazada",
                canceled_work_order: "Esta maquila ha sido cancelada",
                files_placeholder: "Presiona aquí o arrastra tus archivos PDF, Excel o imágenes"
            }
        },
        work_order_list: {
            header: {
                work_order_id: "Nº MAQUILA",
                customer: "CLIENTE",
                work_order_type: "TIPO",
                status: "ESTATUS",
                created_at: "FECHA DE SOLICITUD"
            },
            filters: {
                work_order_id: "Nº maquila",
                status: "Estatus",
                work_order_type: "Tipo de maquila"
            },
            footer: {
                no_work_orders: "No hay maquilas para mostrar",
                work_order: "maquilas"
            },
            queued: "En cola",
            view_work_order: "Ver maquila",
            on_date: "El"
        }
    },
    replenishment_labels: {
        dialog_title: "Imprimir ID de recibo de inventario",
        label_title: "Identificador de recibo de inventario",
        label_instructions: "Imprime y pega esta etiqueta en el paquete retornado para identificar el retorno al realizar putaway.",
        store: "Tienda",
        warehouse: "Almacén",
        replenishment_id: "ID de recibo"
    },
    replenishment: {
        expected_quantity: "piezas pendientes de recibir",
        error_unexpected_stock_start: "El máximo de piezas a recibir por producto es la cantidad esperada. ",
        error_unexpected_stock_middle: "Ajusta la cantidad que se recibirá limitando las piezas pendientes a recibir",
        error_unexpected_stock_end: " y separa las piezas sobrantes para procesarlas en otro recibo."
    }
}