import { ShoppingBagIcon } from '@heroicons/react/solid'
import { useQuery } from "react-query";
import { fetchOrder, getPickingList } from "../../services";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {PaperClipIcon} from "@heroicons/react/outline";
import {FormattedDate, getSkus, Loader, ShippingIncidents} from "../../components";
import { OrderLineItem } from './OrderLineItem';
import { FormattedRelativeTime } from '../../components/FormattedRelativeTime';

import { StatusHighlighted } from '../../components/NewTable/StatusHighlighted';
import { ORDER_STATUSES, TRACKING_STATUSES_DEFINITIONS } from '../../components/NewTable/StatusHighlightedDefinitions';
import { getOrderStatusDate } from "../../utils/orderUtils";

import { StatusPill } from '../../components/BasicTable/StatusPill';
import { Button } from 'antd';
import { useContext } from 'react';
import { UserContext } from '../../hooks/UserContext';
import { ProofOfDelivery } from '../../components/ProofOfDelivery';
import { CubboLastmileEvents } from '../../components/CubboLastmileEvents';
import { getCubboLastmileOrderEvents } from '../../services/cubboLastmileService';
import { SkeletonLoader } from '../../components/SkeletonLoader';

const DEFAULT_QUERY_STALE_TIME = 1000 * 60 * 5 // 5 minutes

export const ShowOrderContainer = ({
    showIncidents = true,
    showCubboLastmileEvents = false,
}) => {

    let { id } = useParams()
    const { t, i18n} = useTranslation();
    const {user} = useContext(UserContext)
    const {
        isLoading,
        isFetching,
        isError,
        error,
        data,
    } = useQuery(['order', id], ()=>fetchOrder(id))

    const {
        isLoading: isLoadingCubboLastmileOrderEvents,
        data: cubboLastmileOrderEvents,
    } = useQuery(['cubbo-lastmile-order-events', id], () => getCubboLastmileOrderEvents(id), {
        refetchOnWindowFocus: false,
        staleTime: DEFAULT_QUERY_STALE_TIME,
        enabled: showCubboLastmileEvents
    })

    const buildStatusHighlighted = (order) => {
        const orderStatusDate = getOrderStatusDate(order)

        return (
            <div order={order}>
                <StatusHighlighted
                    className=""
                    status={data.virtual_status}
                    statuses={ORDER_STATUSES}
                />
                {!!orderStatusDate && (
                    <div className="mt-3 ml-0.5">
                        <div className="mt-2 ml-0.5 text-gray-400 text-sm">
                            {i18n.t('orders.list.at')} <FormattedDate date={orderStatusDate} shortDate={true}/>
                        </div>
                    </div>
                )}
            </div>
        )
    }

    const handleDownloadPickingList = (picking_order_id) => {
        getPickingList(picking_order_id)
    }

    const shouldShowPickingList = (status) => {
        return ['processing', 'picking', 'packing'].includes(status) && user?.permissions?.pick_all_order_stocks
    }

    return (
        <>
            {isLoading ? (
                <><Loader show={true}></Loader></>
            ) : isError ? (
                <>Error: {error.message}</>
            ) : (
                    <div>
                        <div className="">
                            <dl>
                                <div className="even:bg-gray-50 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-base font-medium text-gray-500">Número de orden</dt>
                                    <dd className="mt-1 text-base text-gray-900 sm:mt-0 sm:col-span-2">
                                        #{data.order_number}
                                    </dd>
                                </div>
                                <div className="even:bg-gray-50 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-base font-medium text-gray-500">Estado</dt>
                                    <dd className="mt-1 text-base text-gray-900 sm:mt-0 sm:col-span-2">
                                        {buildStatusHighlighted(data)}
                                    </dd>
                                </div>
                                <div className="even:bg-gray-50 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-base font-medium text-gray-500">Estatus de Envio</dt>
                                    <dd className="mt-1 text-base text-gray-900 sm:mt-0 sm:col-span-2">
                                    
                                     {data.shipping_status ?
                                        <>
                                            <StatusHighlighted
                                                className=""
                                                status={data.shipping_status}
                                                statuses={TRACKING_STATUSES_DEFINITIONS}
                                            />
                                            {data.estimated_time_arrival && data.shipping_status != 'shipment_delivered' && <div className={`mt-2 text-sm ${data.is_delayed ? 'text-red-400' : ''}`}>
                                            {i18n.t("orders.list.eta")}: <FormattedRelativeTime forceDays={true} date={data.estimated_time_arrival}/>
                                            </div>}
                                            { data.last_tracking_event?.reference_timestamp &&
                                                <div className="mt-3 ml-0.5">
                                                    {(['shipment_delivered','shipment_with_incident','shipment_canceled']).includes(data.shipping_status) ?
                                                    <div className='text-gray-400 text-sm'>
                                                        <div className="inline mr-1">{i18n.t('orders.list.at')}</div>
                                                        <FormattedDate date={data.last_tracking_event.reference_timestamp} shortDate={true}/>
                                                    </div>
                                                    : null
                                                    }
                                                </div>
                                                }
                                        </>
                                        :
                                        <div className="italic"> Sin información </div>
                                    }
                                    </dd>
                                </div>
                                <div className="even:bg-gray-50 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-base font-medium text-gray-500">Fecha de creación</dt>
                                    <dd className="mt-1 text-base text-gray-900 sm:mt-0 sm:col-span-2">
                                        {new Intl.DateTimeFormat("es", {
                                            year: "numeric",
                                            month: "long",
                                            day: "2-digit",
                                            hour: 'numeric',
                                            minute: 'numeric'
                                        }).format(new Date(data.channel_created_at))}
                                    </dd>
                                </div>
                                <div className="even:bg-gray-50 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-base font-medium text-gray-500">{i18n.t("orders.pod.title")}</dt>
                                    <dd className="mt-1 text-base text-gray-900 sm:mt-0 sm:col-span-2">
                                        <ProofOfDelivery order={data?.id} proofs={data?.proof_of_delivery} innerClassName='flex flex-col-reverse text-base' />
                                    </dd>
                                </div>
                                <div className="even:bg-gray-50 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-base font-medium text-gray-500">Tienda</dt>
                                    <dd className="mt-1 text-base text-gray-900 sm:mt-0 sm:col-span-2">
                                        {data.store.name}
                                    </dd>
                                </div>
                                {/*<div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-base font-medium text-gray-500">Cliente</dt>
                                    <dd className="mt-1 text-base text-gray-900 sm:mt-0 sm:col-span-2">
                                        ---
                                    </dd>
                                    </div>*/}
                                <div className="even:bg-gray-50 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-base font-medium text-gray-500">Método de envío</dt>
                                    <dd className="mt-1 text-base text-gray-900 sm:mt-0 sm:col-span-2">
                                        {data.shipping_method?.shipping_name}{ data.shipping?.first_name && <br/>}
                                    </dd>
                                </div>
                                {showCubboLastmileEvents && (
                                    <div className="even:bg-gray-50 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-base font-medium text-gray-500">{i18n.t("cubbo_lastmile_events.order_events")}</dt>
                                        <dd className="mt-1 text-base text-gray-900 sm:mt-0 sm:col-span-2">
                                            <SkeletonLoader isLoading={isLoadingCubboLastmileOrderEvents}>
                                                <CubboLastmileEvents cubboLastmileEvents={cubboLastmileOrderEvents?.cubbo_lastmile_events} />
                                            </SkeletonLoader>
                                        </dd>
                                    </div>
                                )}
                                {showIncidents && data?.shipping_incidents.length > 0  &&
                                    <div className="even:bg-gray-50 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-base font-medium text-gray-500">Incidentes creados a paquetería</dt>
                                        <dd className="mt-1 text-base text-gray-900 sm:mt-0 sm:col-span-2">
                                                <ShippingIncidents shippingIncidents={data?.shipping_incidents} t={t}/>
                                        </dd>
                                    </div>
                                }
                                <div className="even:bg-gray-50 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-base font-medium text-gray-500">Información de envío</dt>
                                    <dd className="mt-1 text-base text-gray-900 sm:mt-0 sm:col-span-2">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td> Nombre:</td>
                                                    <td className='text-gray-500'> {data.shipping?.first_name} {data.shipping?.last_name}{ data.shipping?.last_name && <br/>} </td>
                                                </tr>
                                                <tr>
                                                    <td> Teléfono:</td>
                                                    <td className='text-gray-500'> {data.shipping?.phone}{ data.shipping?.phone && <br/>} </td>
                                                </tr>
                                                <tr>
                                                    <td> Email:</td>
                                                    <td className='text-gray-500'> {data.shipping?.email}{ data.shipping?.email && <br/>} </td>
                                                </tr> 
                                                <tr>
                                                    <td> Dirección:</td>
                                                    <td className='text-gray-500'> {data.shipping?.address_1} {data.shipping?.address_2}{ data.shipping?.address_2 && <br/>} </td>
                                                </tr>
                                                <tr>
                                                    <td> País:</td>
                                                    <td className='text-gray-500'> {data.shipping?.country}{ data.shipping?.country && <br/>} </td>
                                                </tr>
                                                <tr>
                                                    <td> Estado:</td>
                                                    <td className='text-gray-500'> {data.shipping?.state}{ data.shipping?.state && <br/>} </td>
                                                </tr>
                                                <tr>
                                                    <td> Ciudad:</td>
                                                    <td className='text-gray-500'> {data.shipping?.city}{ data.shipping?.city && <br/>} </td>
                                                </tr>
                                                <tr>
                                                    <td> CP:</td>
                                                    <td className='text-gray-500'> {data.shipping?.zip_code}{ data.shipping?.zip_code && <br/>} </td>
                                                </tr>
                                            </tbody>   
                                               
                                        </table>
                                        {/* {data.shipping?.first_name}{ data.shipping?.first_name && <br/>}
                                        {data.shipping?.last_name}{ data.shipping?.last_name && <br/>}
                                        {data.shipping?.email}{ data.shipping?.email && <br/>}
                                        {data.shipping?.phone}{ data.shipping?.phone && <br/>}
                                        {data.shipping?.address_1}{ data.shipping?.address_1 && <br/>}
                                        {data.shipping?.address_2}{ data.shipping?.address_2 && <br/>}
                                        {data.shipping?.city}{ data?.shipping?.city && <br/>}
                                        {data.shipping?.state}{ data?.shipping?.state && <br/>}
                                        {data.shipping?.zip_code}{ data?.shipping?.zip_code && <br/>}
                                        {data.shipping?.country}{ data?.shipping?.country && <br/>} */}
                                    </dd>
                                </div>
                                <div className="even:bg-gray-50 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-base font-medium text-gray-500"><p>Información de facturación</p><a>Editar Información</a></dt>
                                    <dd className="mt-1 text-base text-gray-900 sm:mt-0 sm:col-span-2">
                                    <table>
                                                <tr>
                                                    <td> Nombre:</td>
                                                    <td className='text-gray-500'> {data.billing?.first_name} {data.billing?.last_name}{ data.billing?.last_name && <br/>} </td>
                                                </tr>
                                                <tr>
                                                    <td> Dirección:</td>
                                                    <td className='text-gray-500'> {data.billing?.address_1} {data.billing?.address_2}{ data.billing?.address_2 && <br/>} </td>
                                                </tr>
                                                <tr>
                                                    <td> País:</td>
                                                    <td className='text-gray-500'> {data.billing?.country}{ data.billing?.country && <br/>} </td>
                                                </tr>
                                                <tr>
                                                    <td> Estado:</td>
                                                    <td className='text-gray-500'> {data.billing?.state}{ data.billing?.state && <br/>} </td>
                                                </tr>
                                                <tr>
                                                    <td> Ciudad:</td>
                                                    <td className='text-gray-500'> {data.billing?.city}{ data.billing?.city && <br/>} </td>
                                                </tr>
                                                <tr>
                                                    <td> CP:</td>
                                                    <td className='text-gray-500'> {data.billing?.zip_code}{ data.billing?.zip_code && <br/>} </td>
                                                </tr>
                                                
                                               
                                        </table>
                                        {/* {data.billing?.first_name}{ data.billing?.first_name && <br/>}
                                        {data.billing?.last_name}{ data.billing?.last_name && <br/>}
                                        {data.billing?.address_1}{ data.billing?.address_1 && <br/>}
                                        {data.billing?.address_2}{ data.billing?.address_2 && <br/>}
                                        {data.billing?.country}{ data.billing?.country && <br/>}
                                        {data.billing?.state}{ data.billing?.state && <br/>}
                                        {data.billing?.zip_code}{ data.billing?.zip_code && <br/>} */}
                                    </dd>
                                    
                                </div>

                                {
                                    data.picking_order && (
                                    <div className="even:bg-gray-50 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-base font-medium text-gray-500">Picking </dt>
                                        <dd className="mt-1 text-base text-gray-900 sm:mt-0 sm:col-span-2">
                                            <table>
                                                <tr>
                                                    <td> Picker:</td>
                                                    <td className='text-gray-500'>{data.picking_order.assigned_to?.first_name} {data.picking_order.assigned_to?.last_name}</td>
                                                </tr>
                                                <tr>
                                                    <td>Asignado:</td>
                                                    <td className='text-gray-500'><FormattedDate date={data.picking_order.assigned_at}/></td>
                                                </tr>
                                                <tr>
                                                    <td>Iniciado:</td>
                                                    <td className='text-gray-500'><FormattedDate date={data.picking_order.tote_assigned_at}/></td>
                                                </tr>
                                                <tr>
                                                    <td>Tote:</td>
                                                    <td className='text-gray-500'>{data.picking_order.tote?.unique_code}</td>
                                                </tr>
                                                { shouldShowPickingList(data.status) &&
                                                <tr>
                                                    <td>Lista:</td>
                                                    <td className='text-gray-500'><a className='pointer'
                                                    onClick={() => handleDownloadPickingList(data.picking_order.id)}>Descargar</a> </td>
                                                </tr>
                                                }
                                                <tr>
                                                    <td>Completado:</td>
                                                    <td className='text-gray-500'><FormattedDate date={data.picking_order.completed_at}/></td>
                                                </tr>
                                            </table>
                                        </dd>
                                    </div>
                                    )
                                }

                                {
                                    data.shipping_labels[0]?.created_by && (
                                    <div className="even:bg-gray-50 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-base font-medium text-gray-500">Packing</dt>
                                        <dd className="mt-1 text-base text-gray-900 sm:mt-0 sm:col-span-2">
                                            <table>
                                                <tr>
                                                    <td> Packer:</td>
                                                    <td className='text-gray-500'> {data.shipping_labels[0].created_by.first_name} {data.shipping_labels[0].created_by.last_name}</td>
                                                </tr>
                                                <tr>
                                                    <td>Guía impresa:</td>
                                                    <td className='text-gray-500'><FormattedDate date={data.shipping_date}/></td>
                                                </tr>
                                            </table>
                                        </dd>
                                    </div>
                                    )
                                }
                                
                                <div className="even:bg-gray-50 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-base font-medium text-gray-500">Productos</dt>
                                    <dd className="mt-1 text-base text-gray-900 sm:mt-0 sm:col-span-2">
                                        <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                                            {data?.order_lines.map((order_line)=>
                                                <li className="pl-3 pr-4 py-3 text-md" key={order_line.id}>
                                                    <OrderLineItem
                                                        orderLine={order_line}
                                                    />
                                                </li>
                                            )}
                                        </ul>
                                    </dd>
                                </div>
                            </dl>
                        </div>

                    </div>
            )}
        </>
    )
};