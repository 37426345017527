import { useState, useRef, useMemo } from 'react';
import { Route, Switch, useHistory, useParams } from "react-router-dom";
import { useQuery } from "react-query";

import { fetchPickAndShipOrders } from "../../services";

import { PICK_N_SHIP, SHOW_PICK_N_SHIP_ORDER } from "../../navigation/constants"

import {
    BasicTable,
    Button,
    Loader,
    PageTopBar,
    PageView,
    SlidePanel,
} from "../../components"

import { ShowOrderContainer } from "./ShowOrderContainer";
import { PICK_N_SHIP_STATUSES } from '../../components/BasicTable/StatusPillDefinitions';
import {SelectColumnFilter, SelectColumnMultiFilter} from '../../components/BasicTable/modules/ColumnFiltersUI';
import { StatusPill } from '../../components/BasicTable/StatusPill'
import {fetchWarehouseShippingMethods} from "../../services/warehouseSevices";

export const PickAndShipContainer = () => {
    const table_columns = [
        {
            Header: 'Tienda',
            accessor: 'store',
            disableFilters: false,
            disableSortBy: false
        },
        {
            Header: 'Nº de orden',
            accessor: 'order_number', // accessor is the "key" in the data
            disableFilters: false,
            disableSortBy: false,
        },
        {
            Header: 'N° de tracking',
            accessor: 'tracking_number',
            disableFilters: false,
            disableSortBy: true,
        },
        {
            Header: 'Método de envío',
            accessor: 'shipping_method',
            disableFilters: false,
            disableSortBy: false,
            Filter: SelectColumnFilter,
            selectFilterOptions: {}
        },
        {
            Header: 'Estatus de Pick N Ship',
            accessor: 'pick_n_ship_status', // accessor is the "key" in the data
            Filter: SelectColumnMultiFilter,
            selectFilterOptions: PICK_N_SHIP_STATUSES
                .filter(pick_n_ship_status => pick_n_ship_status.filterable)
                .map(pick_n_ship_status => ({ id: pick_n_ship_status.status, label: pick_n_ship_status.label })),
            disableFilters: false,
            disableSortBy: false,
        }
    ]

    const history = useHistory()
    const { warehouse_id } = useParams()

    const defaultSearchParams = {
        page: 1,
        order_by: 'order_number',
        warehouse_id: !isNaN(warehouse_id) && !isNaN(parseInt(warehouse_id)) ? parseInt(warehouse_id) : null
    }

    const [searchParams, setSearchParams] = useState({...defaultSearchParams})

    const [selectedOrders, setSelectedOrders] = useState([])

    const {
        data: shippingMethodsData,
        isLoading: isLoadingShippingMethods ,
        isError: isErrorShippingMethods,
        error: shippingMethodsError,
        isPreviousData: isShippingMethodsDataPrevious
    } = useQuery(
        ['shipping_methods', warehouse_id],
        () => fetchWarehouseShippingMethods(warehouse_id),
        {
            keepPreviousData : true,
            refetchOnWindowFocus: false
        })

    const {
        isLoading: isLoadingOrders,
        isError: isOrdersError,
        error: ordersError,
        data: { orders, meta: paginationMeta } = {},
        isFetching,
        isPreviousData: isOrdersDataPrevious
    } = useQuery(
        ['pick_n_ship_orders', searchParams],
        () => fetchPickAndShipOrders(searchParams),
        { keepPreviousData : true }
    )

    const handlerOnClickStatus = order =>
        history.push(SHOW_PICK_N_SHIP_ORDER.replace(':id', order.id).replace(':warehouse_id', warehouse_id))

    const onSortChange = function (orderBy) {
        tableRef.current.resetSelection()
        tableRef.current.resetPagination()

        if (orderBy.length > 0) {
            setSearchParams({
                ...searchParams,
                order_by: orderBy[0].id,
                order: orderBy[0].desc ? 'desc' : 'asc'
            })
        } else if ('order_by' in searchParams) {
            setSearchParams({
                ...searchParams,
                order_by: defaultSearchParams.order_by,
                order: defaultSearchParams.order
            })
        }
    }

    const onFilterChange = function (activeFilters) {
        memoizedColumns.forEach(col => {
            if (searchParams[col.accessor]) {
                delete searchParams[col.accessor]
            }
        })

        const filters = []
        activeFilters.forEach(filter => { filters[filter.id] = filter.value })

        tableRef.current.resetSelection()
        tableRef.current.resetPagination()

        setSearchParams({ ...searchParams, ...filters, page:1 })
    }

    const getTableData = () => orders.map(order => ({
        store: <div className="">{order.store_name}</div>,
        order_number: <div className="hover:underline cursor-pointer " onClick={() => handlerOnClickStatus(order)}>#{order.order_number}</div>,
        shipping_method: order.shipping_method ? `${order.shipping_method.carrier_name} ${order.shipping_method.shipping_name}` : '-',
        tracking_number: <div className="hover:underline cursor-pointer " onClick={() => handlerOnClickStatus(order)}>{order.tracking_number}</div>,
        pick_n_ship_status: <StatusPill className="cursor-pointer" status={order.pick_n_ship_status} statuses={PICK_N_SHIP_STATUSES} onClick={() => handlerOnClickStatus(order)}/>,
    }))

    const getColumns = () => {
        if(!shippingMethodsData) {
            return []
        }

        const shipping_method_column_index = table_columns.findIndex(column => column.accessor === "shipping_method")

        const newColumns = [...table_columns]

        newColumns[shipping_method_column_index].selectFilterOptions = shippingMethodsData.map(shippingMethod => ({
            id: shippingMethod.carrier_name + " " + shippingMethod.shipping_name,
            label: shippingMethod.carrier_name +" "+ shippingMethod.shipping_name
        }))

        return newColumns
    }

    const memoizedColumns = useMemo(() => getColumns(), [shippingMethodsData])

    const onReceiveOrders = () => console.log('onReceiveOrders')

    const isLoading = () => (!isOrdersDataPrevious && isLoadingOrders) || (!isShippingMethodsDataPrevious && isLoadingShippingMethods)

    const tableRef = useRef()

    return (
        <>
            <PageView
                topMenu={
                    <PageTopBar>
                        <div className="text-lg font-semibold">Pedidos Pick And Ship</div>
                        <Button className="whitespace-nowrap mr-2" onClick={onReceiveOrders} disabled={selectedOrders.length < 1}>
                            Recibir pedidos
                        </Button>
                    </PageTopBar>
                }
                childrenFullWidth={true}
                topMenuFullWidth={true}
            >
                {isLoading() && <Loader show={true}/> }
                {!isLoading() && isOrdersError && <>Error: {ordersError.message}</> }
                {!isLoading() && isErrorShippingMethods && <>Error: {shippingMethodsError.message}</> }
                {!isLoading() && !isOrdersError && !isErrorShippingMethods &&
                    <BasicTable
                        showHeader
                        showLoader={isFetching && isOrdersDataPrevious}
                        columns={memoizedColumns}
                        filterable
                        sortable
                        showPaginator
                        data = {getTableData()}
                        onFilterChange={(activeFilters) => onFilterChange(activeFilters)}
                        onSortChange={(activeSort) => onSortChange(activeSort)}
                        paginationMeta={paginationMeta}
                        onPaginationChange= { requestedPage => setSearchParams({...searchParams, page: requestedPage}) }
                        onSelectionChange={rows => setSelectedOrders(rows)}
                        showDataStatus
                        isFetching={isFetching}
                        emptyTableText="No hay pedidos que mostrar."
                        ref={tableRef}
                    />
                }
            </PageView>
            <Switch>
                <Route exact path={SHOW_PICK_N_SHIP_ORDER}>
                    <SlidePanel title="Ver Orden" referrer={PICK_N_SHIP.replace(':warehouse_id', warehouse_id)}>
                        <ShowOrderContainer />
                    </SlidePanel>
                </Route>
            </Switch>
        </>
    )
}