import { useEffect, useState } from 'react'
import { DialogView, SearchInput } from '../../components'
import { fetchStoresWithBillingConfigurations } from '../../services'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'

export const DuplicateConfigFromStoreModal = ({ open, setOpen, onConfirm = () => {} }) => {
  const { warehouse_id } = useParams()

  const [filterStoreName, setFilterStoreName] = useState('')
  const [storeBillingConfigurations, setStoreBillingConfigurations] = useState([])
  const [selectedBillingConfigId, setSelectedBillingConfigId] = useState(null)
  const [hasMoreResults, setHasMoreResults] = useState(false)
  const [loading, setLoading] = useState(false)

  const getStoreList = async () => {
    setLoading(true)
    if (!filterStoreName || filterStoreName === '') {
      setStoreBillingConfigurations([])
      setSelectedBillingConfigId(null)
      setHasMoreResults(false)
      return
    }
    const searchParams = {
      page: 1,
      per_page: 5,
      having_current_billing_configuration: true,
      store_name: filterStoreName,
      warehouse_id: warehouse_id,
    }
    const response = await fetchStoresWithBillingConfigurations(searchParams)

    const responseStoreBillingConfigurations = []
    response.stores.forEach(store => {
      responseStoreBillingConfigurations.push({
        store_id: store.id,
        store_name: store.name,
        billing_configuration_id: store.current_billing_configuration_with_warehouse_ids.id,
      })
    })
    setStoreBillingConfigurations(responseStoreBillingConfigurations)

    const totalPages = response.meta.total_pages
    if (totalPages > 1) {
      setHasMoreResults(true)
    } else {
      setHasMoreResults(false)
    }

    setLoading(false)
  }

  useEffect(() => {
    getStoreList()
  }, [filterStoreName])

  const confirmDisabled = () => {
    return !selectedBillingConfigId || loading
  }

  return (
    <DialogView open={open} setOpen={setOpen}>
      <div className="">
        <SearchInput inputValue={filterStoreName} onChange={setFilterStoreName} debounce={200} placeholder={'Buscar tienda'} />
      </div>
      <div className="my-3">
        <div>
          {}
          <label className="block text-sm font-medium text-gray-700">
            {filterStoreName && filterStoreName !== ''
              ? 'Selecciona la tienda de la que deseas duplicar la configuración'
              : 'Escribe el nombre de la tienda de la que deseas duplicar la configuración'}
          </label>
        </div>
        <div>
          {storeBillingConfigurations.map((storeBillingConfiguration, index) => (
            <div key={storeBillingConfiguration.billing_configuration_id} >
              <label className="inline-flex items-center mt-3">
                <input
                  type="radio"
                  value={storeBillingConfiguration.billing_configuration_id}
                  checked={selectedBillingConfigId == storeBillingConfiguration.billing_configuration_id}
                  onChange={e => {setSelectedBillingConfigId(e.target.value)}}
                />
                <span className="ml-2">{storeBillingConfiguration.store_name}</span>
              </label>
            </div>
          ))}
        </div>
        {hasMoreResults && <div>*Se muestran los primeros 5 resultados, refina tu búsqueda para encontrar la tienda que deseas</div>}
      </div>
      <div className="flex">
        <button
          type="button"
          className="mx-2 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
          onClick={() => onConfirm(selectedBillingConfigId)}
          disabled={confirmDisabled()}
          style={confirmDisabled() ? { opacity: 0.5 } : {}}
        >
          Aceptar
        </button>
        <button
          type="button"
          className="mx-2 mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
          onClick={() => {
            setOpen(false)
          }}
        >
          Cancelar
        </button>
      </div>
    </DialogView>
  )
}
