import { useTranslation } from "react-i18next";
import { useState, useRef, useMemo,useContext, useEffect } from "react"
import { Route, Switch, useHistory, useParams } from "react-router-dom"
import { useQuery, useQueryClient } from "react-query"
import { FormattedRelativeTime } from '../../components/FormattedRelativeTime';
import { StatusHighlighted } from '../../components/NewTable/StatusHighlighted';
import { ORDER_STATUSES, TRACKING_STATUSES_DEFINITIONS, TRACKING_DESCRIPTIONS, TRACKING_STATUSES, TRACKING_INCIDENT_FINAL_DESCRIPTIONS  } from '../../components/NewTable/StatusHighlightedDefinitions';

import { fetchShippedOrders, getLabel,getShipingLabels } from "../../services"
import {UserContext} from "../../hooks/UserContext";
import { ChevronRightIcon } from "@heroicons/react/outline"
import { useDispatch, useSelector } from "react-redux"
import {
  ActionMenu,
  ConfirmDialog,
  DialogView,
  FormattedDate,
  NewTable,
  PageTopBar,
  PageView,
  SlidePanel,
} from "../../components"
import { ORDERS_SHIPPED } from "../../navigation"
import { StatusPill } from "../../components/BasicTable/StatusPill"
import { ORDER_STATUSES_SHIPPED, SHIPPING_INCIDENT_CATEGORIES, SHIPPING_INCIDENT_STATUSES } from "../../components/BasicTable/StatusPillDefinitions"
import { Loader } from "../../components/Loader"
import { LabelView } from "./GenerateShipment/LabelView"
import { CREATE_RETURN, RECEIVE_RETURN, SHIPPED_ORDER_DETAIL } from "../../navigation/constants"
import { CreateReturnContainer } from "./CreateReturn/CreateReturnContainer"
import { ShowOrderContainer } from "./ShowOrderContainer"
import { fetchWarehouse, fetchWarehouseShippingMethods } from "../../services/warehouseSevices";
import { setShippedOrdersListPageSize } from '../../redux/pageSizeSlice';
import { Dropdown, Menu } from "antd";
import ShippedOrderMultiselection from "../../components/ShippedOrderMultiselection/ShippedOrderMultiselection"
import { ACTIONS, IncidentActionModal } from "../../components/ShippedOrderMultiselection/IncidentActionModal"
import { cloneDeep } from "lodash"
import { fetchShippedOrdersSummary } from "../../services/orderServices";
import LogRocket from "logrocket";
import { INCIDENT_STATUSES } from "../../components/ShippedOrderMultiselection/IncidentStatusDefinitions";
import { getOrderStatusDate } from "../../utils/orderUtils";

const DEFAULT_QUERY_STALE_TIME = 1000 * 60 * 60 // 60 minutes

const statusFilters = ORDER_STATUSES_SHIPPED.filter(
  (status) => status.filterable !== false).map((status) => {
      return status.filterable === false
          ? null : { id: status.status, label: status.label}
      }
)

const shippingStatusFilters = TRACKING_STATUSES_DEFINITIONS.filter(
  (status) => status.filterable !== false).map((status) => {
      return status.filterable === false
          ? null : { id: status.status, label: status.label}
      }
)

const shippingIncidentStatusFilters = SHIPPING_INCIDENT_STATUSES.filter(
  (status) => status.filterable !== false).map((status) => {
      return status.filterable === false
          ? null : { id: status.status, label: status.label}
      }
)

const shippingIncidentCategoriesFilters = SHIPPING_INCIDENT_CATEGORIES.filter(
  (status) => status.filterable !== false).map((status) => {
      return status.filterable === false
          ? null : { id: status.status, label: status.label}
      }
)

// TODO: List all channel integrations
const channelIntegrationsFilters = ['coppel', 'liverpool', 'manual', 'meli', 'shopify', 'vtex', 'walmart'].map((channel_type)=>{
  return {
      id: channel_type,
      label: channel_type
  }
})

export const ListOrdersShippedContainer = () => {
    const { i18n } = useTranslation();
    const history = useHistory()
    const {user} = useContext(UserContext)
    const {warehouse_id} = useParams()
    const [openDialogLabel, setOpenDialogLabel] = useState(false)
    const [openDialogBilling, setOpenDialogBilling] = useState(false)
    const [openDialogDocument, setOpenDialogDocument] = useState(false)
    const [printInformation, setPrintInformation] = useState(false)
    const [selectOrder, setSelectOrder] = useState(false)
    const [selectedOrders, setSelectedOrders] = useState([])
    const [deselectOrders, setDeselectOrders] = useState(false)
    const dispatch = useDispatch()
    const queryClient = useQueryClient()
    const [isFetchShippedOrdersEnabled, setIsFetchShippedOrdersEnabled] = useState(false)
    const pageSize = useSelector((state) => state.pageSize.shippedOrdersList)
    const initialSearchParams = {
      warehouse_id: warehouse_id,
      page:1,
      per_page: pageSize,
      status: ['shipped', 'returned', 'returning', 'waiting_pick_up', 'return_arrived', 'reentered' ],
      order_by: "shipping_date",
      load_channel_integration: true
    }
  const [searchParams, setSearchParams] = useState(initialSearchParams)
  const [orders, setOrders] = useState([])
  const createReturnSlidePanelRef = useRef(null)
  const [incidentActionModalProps, setIncidentActionModalProps] = useState({open: false, action:null, actionParameters:null})

  const permissions = useMemo(() => {
    const localPermissions = {
      // Table permissions
      showIncidents: user ? user.permissions.index_shipped_orders : false,
      enableCreateCarrierReturn: user ? user.permissions.create_returns : false,
      enableMultiSelectOrders: false,

      // Multi select permissions
      enableCreateIncident: user ? user.permissions.create_shipping_incidents : false,
      enableUpdateIncidentStatus: user ? user.permissions.create_shipping_incidents : false,
      enableUpdateShippingStatus: user ? user.permissions.create_shipping_incidents : false,
    }

    // Enable multiselect if needed
    localPermissions.enableMultiSelectOrders = localPermissions.enableCreateIncident || localPermissions.enableUpdateIncidentStatus || localPermissions.enableUpdateShippingStatus

    return localPermissions
  }, [user])

  const {
    data: shippingMethodsData, 
    isLoading: isLoadingShippingMethods ,
    isError: isErrorShippingMethods,
    error: errorShippingMethods,
    isFetching: isFetchingShippingMethods,
    isPreviousData: isPreviousDataShippingMethods,
    refetch: refetchShippingMethods
  } = useQuery(['shipping_methods', warehouse_id], () => fetchWarehouseShippingMethods(warehouse_id), { 
      keepPreviousData : true,
      refetchOnWindowFocus: false
  })

  const {
    isLoading,
    isError,
    error,
    data,
    isFetching,
    isPreviousData,
    refetch,
  } = useQuery(
    ['shipped_orders',searchParams],
    () => fetchShippedOrders(searchParams),
    { keepPreviousData: true, refetchOnWindowFocus: false, enabled: isFetchShippedOrdersEnabled, onSuccess: () => setIsFetchShippedOrdersEnabled(false) }
  )
  
  // const {
  //   isLoadingSummary,
  //   isErrorSummary,
  //   errorSummary,
  //   data: dataSummary,
  //   isFetchingSummary,
  //   isPreviousDataSummary,
  //   refetchSummary,
  // } = useQuery(
  //   ['shipped_orders_summary', warehouse_id],
  //   () => fetchShippedOrdersSummary({warehouse_id: warehouse_id,}),
  //   { keepPreviousData: true }
  // )

  const {
    isLoading: isLoadingWarehouse,
    isError: isErrorWarehouse,
    error: errorWarehouse,
    data: warehouse,
    isPreviousData: isPreviousDataWarehouse,
} = useQuery('warehouse', () => fetchWarehouse(warehouse_id), { keepPreviousData: true, refetchOnWindowFocus: true, staleTime: DEFAULT_QUERY_STALE_TIME}) 

  const handlerOnClickStatus = (order) => {
    history.push(SHIPPED_ORDER_DETAIL.replace(":id", order.id).replace(':warehouse_id', warehouse_id))
  }

  const [shippingMethodFilters, setShippingMethodFilters] = useState([])
  
  const onSortChange = (orderBy) => {
    if (orderBy.length > 0) {
      const newSearchParams = {
        ...searchParams,
        order_by: orderBy[0].id,
        order: orderBy[0].desc ? "desc" : "asc",
      }

      if(newSearchParams.order_by !== searchParams.order_by || newSearchParams.order !== searchParams.order){
        setSearchParams(newSearchParams)

        if(orders?.length > 0){
          setIsFetchShippedOrdersEnabled(true)
        }
      }
    } else {
      if ("order_by" in searchParams) {
        const newSearchParams = {...searchParams}
        newSearchParams.order_by = "shipping_date"
        delete newSearchParams.order

        if(newSearchParams.order_by !== searchParams.order_by || newSearchParams.order !== searchParams.order){
          setSearchParams(newSearchParams)

          if(orders?.length > 0){
            setIsFetchShippedOrdersEnabled(true)
          }
        }
      }
    }
  }

  const handlerOnClickTracking = (e, order) => {
    e.stopPropagation()
    const shipping_label = order.shipping_labels.find((label) => ['Shipping','Cash On Delivery','Pickup'].includes(label.label_type) && label.status === 'VALID' && label.tracking_url)
    window.open(shipping_label.tracking_url)
  }

  const getShippingLabel = async (order_id, document_type = "label") => {
    try {
        const response = await getLabel(order_id)
        if(document_type == 'label'){
          print("Imprimir Guía",response.shipping_labels[0].label_file.path, document_type)
        }else{
          print("Imprimir Factura Comercial",response.shipping_labels[0].billing_file.path, document_type)
        }
    } catch (e) {
      console.log(e)
      alert("problem getting shipping label")
    }
  }

  const getExtraShippingLables = async (searchParamsLabel) => {
    try {
        const response = await getShipingLabels(
            searchParamsLabel
        )
        if (response.shipping_labels.length){
          
          print("Imprimir Guía Mercado Envíos",response.shipping_labels[0].label_file.path, "label")
        }else{

        }
    } catch (e) {
      console.log(e?.response?.data?.error)
      alert("problem getting shipping label")
    }
  }

  const print = (title, path, type) => {
    
    setOpenDialogDocument(false)
    setPrintInformation({
        ...printInformation,
        "title": title, 
        "type": type,
        "file_path": path
    })
    if (type != 'document') setOpenDialogLabel(true) 
    else setOpenDialogDocument(true)
}

  const getMenuOptions = (order) => {
    const options = [
      {
        title: "Ver orden",
        clickHandler: () =>
          history.push(SHIPPED_ORDER_DETAIL.replace(":id", order.id).replace(':warehouse_id', warehouse_id)),
      }
    ]
     
    if (user.permissions.download_shipping_labels) {
      options.push({
        title: "Imprimir guía Cubbo",
        clickHandler: () => {
          getShippingLabel(order.id)
        },
      })
      if(order?.meli_shipment_id){

          options.push({
            title: "Imprimir guía Mercado Envios",
            clickHandler: () => {
              let searchParamsLabel = {
                order_id: order.id,
                carrier_name: "Mercado Envíos",
                shipping_type: "pickup",
                status: "VALID"
              }
              getExtraShippingLables(searchParamsLabel)
            },
          })
        
      }
    }
    let index = order.invoices.findIndex(invoice => (invoice.purpose === "SALE_INVOICE" || invoice.purpose === "DONATION_INVOICE" || invoice.purpose === "REMOVE_STOCK_INVOICE") && invoice.status === 'APPROVED')
    if(order.invoices.length>0 && index != -1 && user.permissions.download_invoices ){
   
      options.push( {
        title: "Imprimir Etiqueta de Factura",
        clickHandler: () => {
          order.invoices[index].print = true
          setSelectOrder(order)
          print("Imprimir Etiqueta de Factura",order.invoices[index].label_pdf_file.path, 'label')
        }
        
      })
      options.push( {
        title: "Imprimir PDF de Factura",
        clickHandler: () => {
          order.invoices[index].print = true
          setSelectOrder(order)
          print("Imprimir PDF de Factura",order.invoices[index].pdf_file.path, 'document')
        }
        
      })
      options.push( {
        title: "Imprimir XML de Factura",
        clickHandler: () => {
          order.invoices[index].print = true
          setSelectOrder(order)
          print("Imprimir XML de Factura",order.invoices[index].xml_file.path, 'document')
        }
        
      })
    
    }

    if (order.status === "shipped" && (order.virtual_status !== "waiting_pick_up" || order.status !== "return_arrived") && !order.is_pick_and_ship && permissions.enableCreateCarrierReturn) {
      options.push({
        title: "Retornado por paquetería",
        clickHandler: () => {
          history.push(CREATE_RETURN.replace(":orderId", order.id).replace(':warehouse_id', warehouse_id))
        },
      })
      
    }
    // if (order.status == "return_arrived" && !order.is_pick_and_ship && permissions.enableCreateCarrierReturn) {
    //   options.push({
    //     title: "Recibir retorno por paquetería",
    //     clickHandler: () => {
    //       history.push(CREATE_RETURN.replace(":orderId", order.id).replace(':warehouse_id', warehouse_id))
    //     },
    //   })
    // }
    if(order.shipping_method.shipping_name == "Internacional"){
        options.push( {
            title: "Imprimir Factura Comercial",
            clickHandler: () => {
              getShippingLabel(order.id, 'document')
            }
            
        })
    }
    if(order.shipping_documents.length>0){
      order.shipping_documents.forEach((shipping_document, document_index) => {
        let title = "Imprimir Documento de Envío"
        if (document_index > 0) title += ` ${document_index+1}`
        options.push( {
            title: title,
            clickHandler: () => {
              shipping_document.print = true
              setSelectOrder(order)
              print(title,shipping_document.file.path,shipping_document.printing_type)
            }
            
        })
      })
    }
    return options
  }

  useEffect(() => {
    if (!data) {
      return
    }
    if(data.orders) setOrders(data.orders)
  }, [data])

  const CreateIncidentMenuOverlay = order => {
    // TO DO: show only allowed categories for this order
    return (
      <Menu onClick={menuEvent => { menuEvent.domEvent.stopPropagation()}}>
        {SHIPPING_INCIDENT_CATEGORIES.map( option => {
            let [canCreate, reason] = canCreateShippingIncident(order, option.status)
            if (reason) reason = i18n.t(`orders.shipping_incident.cant_create.${reason}`)
            return (
              <Menu.Item key={option.status} title={reason}
                onClick={(e) => {
                  e.domEvent.stopPropagation()
                  if (canCreate) createOneIncident(order, option.status)}}
                style={canCreate ? {} : {color: 'lightgray', cursor: 'not-allowed'}}>
                {i18n.t(option.label)}
              </Menu.Item>
            )
          }
        )}
      </Menu>
    )
  }

  const CreateIncidentMenu = ({order}) => {
    return (
      <Dropdown disabled={false} overlay={CreateIncidentMenuOverlay(order)} placement="bottomLeft" onClick={(e) => e.stopPropagation()}
        className="p-2 relative inline-block text-left">
          <button className="inline-flex justify-center border border-gray-300 shadow-sm rounded-md 
            text-gray-700 text-base hover:bg-gray-100 hover:text-gray-700 hover:border-blue-400
            focus:outline-none bg-white outline-none"
            >{i18n.t("orders.list.create_incident")}
          </button>
      </Dropdown>
  )}

  const buildTrackingStatus = function(order) {
      let estimated_time_arrival = new Date(order.estimated_time_arrival)
      let validate_estimated_time_arrival =  estimated_time_arrival > new Date(2020, 1, 1) 
      const noTrackingStatusHighlighted = <>
          {order.requires_external_label ?
              <div className="whitespace-normal pr-2">
                  {i18n.t('orders.list.external_label_desc')}
              </div>
          : ["returned", "returning", "return_arrived", "reentered"].includes(order.status) ? 
              <div className="font-normal text-base italic text-gray-500"> {i18n.t("orders.list.no_information")} </div>
            : <div>
            <div className="font-normal text-base italic text-gray-500"> {i18n.t("orders.list.no_information")} </div>

            {validate_estimated_time_arrival && <div className={`mt-2 text-sm ${order.is_delayed ? 'text-red-400' : ''}`}>
                {i18n.t("orders.list.eta")}: <FormattedRelativeTime time_zone={warehouse?.time_zone} forceDays={true} date={order.estimated_time_arrival}/>
            </div>}
          </div>
          }
          </>

      if (!order.shipping_status || ["shipment_created"].includes(order.shipping_status)) return noTrackingStatusHighlighted

      const description = order.last_tracking_event?.metadata?.description
      const tracking_description = order.shipping_status == "shipment_returned" ? 'in_return' : TRACKING_DESCRIPTIONS.has(description) ? description : null

      if (order.status === "shipped" && (TRACKING_STATUSES.has(order.shipping_status) || order.is_delayed)) {
          return (
          <>
              <StatusHighlighted
                  className=""
                  status={order.shipping_status}
                  statuses={TRACKING_STATUSES_DEFINITIONS}
                  description={tracking_description}
              />
              
              {validate_estimated_time_arrival && order.shipping_status != 'shipment_delivered' && order.shipping_status != 'shipment_canceled' && !TRACKING_INCIDENT_FINAL_DESCRIPTIONS.has(tracking_description) && <div className={`mt-2 text-sm ${order.is_delayed ? 'text-red-400' : ''}`}>
                  {i18n.t("orders.list.eta")}: <FormattedRelativeTime time_zone={warehouse?.time_zone} forceDays={true} date={order.estimated_time_arrival}/>
              </div>}
              { order.last_tracking_event?.reference_timestamp &&
              <div className="mt-2 ml-0.5">
                  {(['shipment_delivered','shipment_with_incident','shipment_canceled']).includes(order.shipping_status) ?
                  <div className='text-gray-400 text-sm'>
                      <div className="inline mr-1">{i18n.t('orders.list.at')}</div>
                      <FormattedDate date={order.last_tracking_event.reference_timestamp} shortDate={true}/>
                  </div>
                  : null
                  }
              </div>
              }
          </>
          )
      }

      return noTrackingStatusHighlighted
  }

  const ShippingIncidentsCell = ({ order }) => {
    // TO DO: show create menu only if allowed
    if (!order.shipping_incidents || order.shipping_incidents.length === 0) {
      return permissions.enableCreateIncident ? <CreateIncidentMenu order={order} /> : <span>Sin incidentes</span>
    }
    return (
      <div className="whitespace-normal">
          {permissions.enableCreateIncident && <CreateIncidentMenu order={order} />}

          <div className='w-60 shadow rounded-md py-1.5 px-2 text-gray-700 font-normal text-xs mt-2 bg-gray-50'>
            <label htmlFor="company_website" className="block text-base font-medium text-gray-800">
              {i18n.t(`orders.shipping_incident.category.${order.shipping_incidents[0].category}`)}
            </label>
           
            <div>
              {order.shipping_incidents[0]?.status === "RESOLVED" ?
                <label className="text-sm font-medium text-green-600">
                  {i18n.t(`orders.shipping_incident.status.${order.shipping_incidents[0].status}`)}: {i18n.t(`orders.shipping_incident.resolution.${order.shipping_incidents[0].resolution}`)}
                </label>
                : 
                <>
                  <div>
                    <label htmlFor="company_website" className="block text-sm font-regular text-gray-500">
                      <FormattedRelativeTime date={order.shipping_incidents[0].created_at}/>
                    </label>
                  </div>
                  <label className={`text-sm font-medium ${order.shipping_incidents[0].status === "PENDING" ? 'text-gray-600' :  'text-pink-600' }`}>
                    {i18n.t(`orders.shipping_incident.status.${order.shipping_incidents[0].status}`)}
                  </label>
                </>
              }
            </div>
          </div>
          { order.shipping_incidents.length > 1 &&
            <div className='w-60 shadow rounded-md py-1.5 px-2 text-gray-700 font-normal text-xs mt-2 bg-gray-50 flex flex-row justify-between cursor-pointer'  >
              <label htmlFor="company_website" className="block text-base font-regular text-gray-500">
                + {order.shipping_incidents.length - 1 } {i18n.t(`orders.shipping_incident.incident`)}{ order.shipping_incidents.length - 1 ==  1 ? "": "s" }
              </label>
              <span className="block text-base font-regular text-gray-500">
                <ChevronRightIcon className=" h-5 w-5" aria-hidden="true" />
              </span>
            </div>

          }
      </div>
    )
  }

  const buildStatusHighlighted = (order) => {
    const orderStatusDate = getOrderStatusDate(order)

    return (
      <div order={order}>
        <StatusHighlighted
          className=""
          status={order.virtual_status}
          statuses={ORDER_STATUSES}
          onClick={(e)=> {
              e.stopPropagation()
              handlerOnClickStatus(order)
          }}
        />
        {!!orderStatusDate && (
          <div className="mt-3 ml-0.5">
            <div className="mt-2 ml-0.5 text-gray-400 text-sm">
              El <FormattedDate date={orderStatusDate} shortDate={true}/>
            </div>
          </div>
        )}
      </div>
    )
  }

  const showShippingTrackignNumber = (shipping_labels) => {
    shipping_labels =  shipping_labels.find((label) => ['Shipping','Cash On Delivery','Pickup'].includes(label.label_type) && label.status === 'VALID' && label.shipping_number)
    return shipping_labels ? shipping_labels.shipping_number : ''
  }

  const memoizedTableData = useMemo(() => {
    return orders.map((order) => {
      return {
        object: order,
        order_number: (
          <div className="font-normal text-base text-gray-700">
            #{String(order.order_number)}
            <div className="t-1 text-sm text-gray-400">{String(order.store.name)}</div>
            <div className="t-1 text-sm text-gray-400">{order.channel_name}</div>
          </div>
        ),
        shipping_method:
        <>
          <div>
          {order.shipping_method == null
            ? "-"
            : `${order.shipping_method.carrier_name} ${order.shipping_method.shipping_name}`}
          </div>
          {order.shipping_labels[0] ? (
            <a className="hover:underline" onClick={(e) => handlerOnClickTracking(e, order)}>
              {String(showShippingTrackignNumber(order.shipping_labels))}
            </a>) 
            : null}
        </>,
        status: buildStatusHighlighted(order),
        shipping_status: buildTrackingStatus(order),
        shipping_date: <FormattedDate date={order.shipping_date} shortDate />,
        ...(permissions.showIncidents ? {shipping_incident: <ShippingIncidentsCell order={order}/> } : {} ),
        actions: (
          <ActionMenu className="float-right" items={getMenuOptions(order)} />
        ),
      }
    })
  }, [orders, permissions])

  const memoizedColumns = useMemo(() => {
    if(!shippingMethodsData) return []

    // accessor is the "key" in the data
    return [
      {
        Header: "Nº DE ORDEN",
        accessor: "order_number",
      },
      {
        Header: 'MÉTODO DE ENVÍO',
        accessor: 'shipping_method',
      },
      {
        Header: 'Estado',
        accessor: 'status',
      },
      {
        Header: 'Estatus de envío',
        accessor: 'shipping_status',
      },
      ...(permissions.showIncidents ? [{
        Header: "Incidente",
        accessor: "shipping_incident",
        disableSortBy: true
      }] : [] ),
      {
        Header: "",
        accessor: "actions",
        disableSortBy: true,
        fetchingIndicator: true,
      },
    ]
  }, [shippingMethodsData, permissions])

  const callFilterShortcut = (filters) => {
      LogRocket.track('ShippedOrderFilterShortcut', {
        warehouse_id: warehouse_id,
        shortcut: filters.map(filter => `${filter.key}=${filter.value}`).join(', '),
      })
      const newSearchParams = {...searchParams}
      filters.forEach(filter => {
        let currentFilter = filtersData.find(item => item.key == filter.key)
        currentFilter.data.value = filter.value
        currentFilter.data.visible = true
        let filterKey = currentFilter.key_alias ? currentFilter.key_alias : currentFilter.key
        newSearchParams[filterKey] = filter.value
      })
      setSearchParams(newSearchParams)
  }

  const updateFilterOptions = (options, filter) => {
    setFiltersData((prev) => {
        return prev.map((item) => {
            if (item.key === filter) 
                return {
                    ...item,
                    data: {...item.data, options: options}
                }
            else return item
        }).sort((a, b) => a.data.visible > b.data.visible ? -1 : 1) // Sort to preserve the order of the filters
    })
  }

  const updateFiltersData = (updatedData, filter) => {
    // Update filters with new data
    setFiltersData((prev) => {
        return prev.map((item) => {
            if (item.key == filter) 
                return {
                    ...item,
                    data: updatedData
                }
            else return item
        }).sort((a, b) => a.data.visible > b.data.visible ? -1 : 1) // Sort to preserve the order of the filters
    })

    // Set search params only if needed
    const filterItem = filtersData.find(item => item.key == filter)
    if ((Array.isArray(filterItem.data.value) && filterItem.data.value.length === 0 && updatedData.value.length == 0)) return
    if (!['boolean','text'].includes(filterItem.data.type) && filterItem.data.value === updatedData.value) return
    let value = updatedData.value
    const filter_name = filterItem.key_alias || filterItem.key

    switch (filter_name) {
        case "shipping_date":
            if (value?.from && value?.to) { 
                const from = value.from
                const to = value.to
                setSearchParams((prev) => ({...prev, 
                    [filter_name]: {
                        from: from.toDate().toISOString(),
                        to: to.toDate().toISOString()
                    }, page: 1
                }))
            }
            break
        default:
          if (!updatedData.visible) {
              setSearchParams((prev) => {
                  // Delete filter key from searchParams
                  const newSearchParams = {...prev, page: 1}
                  delete newSearchParams[[filter_name]]
                  return newSearchParams
              })
          }
          else {
              setSearchParams((prev) => ({...prev, [filter_name]: value, page: 1}))
          }
          break
    }
  }

  const handleResetFilters = () => {
      setOrders([])
      queryClient.setQueryData(['shipped_orders',searchParams], null)
      setSearchParams(initialSearchParams)
  }

  const filtersInitialData = [
    {
      key: "order_number",
      placeholder: "orders.filters.paste_order_numbers",
      label: "orders.filters.order_number",
      data: {
            visible: true,
            type: 'bulk',
            value: []
      },
    },
    {
      key: "shipping_number",
      placeholder: "orders.filters.paste_shipping_numbers",
      label: 'orders.filters.shipping_number',
      data: {
            visible: true,
            type: 'bulk',
            value: []
      },
    },
    {
      key: "channel_names",
      label: 'orders.filters.channel_name',
      data: {
            visible: false,
            type: 'multiFilter',
            value: [],
            placeholder: 'orders.filters.channel_name_placeholder',
            options: channelIntegrationsFilters
      },
    },
    {
      key: "shipping_method",
      label: 'orders.filters.shipping_method',
      data: {
            visible: false,
            type: 'multiFilter',
            value: [],
            placeholder: 'orders.filters.shipping_method_placeholder',
            options: shippingMethodFilters
      },
    },
    {
      key: "status",
      label: 'orders.filters.status',
      data: {
            visible: false,
            type: 'multiFilter',
            value: [],
            placeholder: 'orders.filters.status_placeholder',
            options: statusFilters
      },
    },
    {
      key: "shipping_status",
      label: 'orders.filters.shipping_status',
      data: {
            visible: true,
            type: 'multiFilter',
            value: [],
            placeholder: 'orders.filters.shipping_status_placeholder',
            options: shippingStatusFilters
      },
    },
    {
      key: "store",
      label: "orders.filters.store",
      data: {
            visible: false,
            type: 'text',
            value: ''
      },
    },
    {
      key: "shipping_date",
      label: "orders.filters.shipping_date",
      data: {
          visible: false,
          type: 'date',
          value: undefined
      },
    },
    {
      key: "is_delayed",
      label: "orders.filters.is_delayed",
      data: {
          visible: false,
          type: 'boolean',
          onClickValue: true
      },
    },
    {
      key: "failed_delivery",
      label: "orders.filters.failed_delivery",
      data: {
          visible: false,
          type: 'boolean',
          onClickValue: true
      },
    },
    {
      key: "first_delivery_on_time",
      label: "orders.filters.first_delivery_on_time",
      data: {
          visible: false,
          type: 'boolean',
          onClickValue: true
      },
    },
    {
      key: "without_shipping_incident",
      key_alias: "with_shipping_incident",
      label: "orders.filters.without_shipping_incident",
      data: {
          visible: false,
          type: 'boolean',
          value: false,
          onClickValue: false
      }
    },
    {
      key: "with_shipping_incident",
      label: "orders.filters.shipping_incident",
      data: {
          visible: false,
          type: 'boolean',
          value: true,
          onClickValue: true
      },
      group: 'shipping_incident',
      index: 0,
    },
    {
      key: "shipping_incident_category",
      label: "orders.filters.shipping_incident_category_placeholder",
      data: {
          visible: false,
          type: 'multiFilter',
          value: [],
          placeholder: "orders.filters.shipping_incident_category_placeholder",
          options: shippingIncidentCategoriesFilters
      },
      group: 'shipping_incident',
      index: 1
    },
    {
      key: "shipping_incident_status",
      label: "orders.filters.shipping_incident_status_placeholder",
      data: {
          visible: false,
          type: 'multiFilter',
          value: [],
          placeholder: "orders.filters.shipping_incident_status_placeholder",
          options: shippingIncidentStatusFilters
      },
      group: 'shipping_incident',
      index: 2
    }
  ]

  const [filtersData, setFiltersData] = useState(filtersInitialData)

  useEffect(() => { // Update the filters option when the shipping methods data is loaded
    const shipping_methods = shippingMethodsData?.map((shipment) => {
      return {
        id: shipment?.id,
        label: shipment?.carrier_name + " " + shipment?.shipping_name
      }
    })
    setShippingMethodFilters(shipping_methods)
    updateFilterOptions(shipping_methods, "shipping_method")
  }, [shippingMethodsData])

  const handleIncidentCreate = category => {
    let newIncidentActionModalProps = {
      open: true,
      action: ACTIONS.CREATE_INCIDENT,
      orders: cloneDeep(selectedOrders),
      actionParameters: { category: category },
    }
    setIncidentActionModalProps(newIncidentActionModalProps)
  }

  const handleIncidentResolution = resolution => {
    LogRocket.track('IncidentResolution', {resolution: resolution})
    let newIncidentActionModalProps = {
      open: true,
      action: ACTIONS.RESOLVE_INCIDENT,
      orders: cloneDeep(selectedOrders),
      actionParameters: { resolution: resolution },
    }
    setIncidentActionModalProps(newIncidentActionModalProps)
  }
  const handleIncidentStatusUpdate = status => {
    LogRocket.track('IncidentStatusUpdate', {status: status})
    let newIncidentActionModalProps = {
      open: true,
      action: ACTIONS.UPDATE_STATUS_INCIDENT,
      orders: cloneDeep(selectedOrders),
      actionParameters: { status: status },
    }
    setIncidentActionModalProps(newIncidentActionModalProps)
  }
  const handleShippingStatusUpdate = (status, description) => {
    LogRocket.track('ShippingStatusUpdate', {status: status, description: description})
    let newIncidentActionModalProps = {
      open: true,
      action: ACTIONS.UPDATE_SHIPPING_STATUS,
      orders: cloneDeep(selectedOrders),
      actionParameters: { status: status, description: description  },
    }
    setIncidentActionModalProps(newIncidentActionModalProps)
  }

  const createOneIncident = (order, category) => {
    let newIncidentActionModalProps = {
      open: true,
      action: ACTIONS.CREATE_INCIDENT,
      orders: [cloneDeep(order)],
      actionParameters: { category: category },
    }
    setIncidentActionModalProps(newIncidentActionModalProps)
  }

  const onActionCancel = result => {
    setIncidentActionModalProps({ ...incidentActionModalProps, open: false })
  }
  const onActionSuccess = result => {
    setIncidentActionModalProps({ ...incidentActionModalProps, open: false })
    handleDeselectOrders()
    refetch()
  }

  const handleDeselectOrders = () => {
    setDeselectOrders(true)
  }

  const canCreateShippingIncident = (order, incident_category) => {
      if (["MISSING_REFERENCES", "MISSING_PRODUCT", "DAMAGED", "CHANGE_ADDRESS", "RESCHEDULE"].includes(incident_category)) return [false, 'temporarily_disabled'] //These categories are temporarily disabled

      if (order.status === 'returned') return [false, 'order_returned'] //Order is already returned
      else if (!['shipped', 'returning'].includes(order.status)) return [false, 'order_not_shipped_or_returning'] //Somehow order is not shipped or returning
      
      const unresolvedSameCategoryIncident = order.shipping_incidents.find(si => si.category === incident_category && si.status !== 'RESOLVED' && si.origin === 'CUBBO')
      if (unresolvedSameCategoryIncident) return [false, 'unresolved_same_category_incident'] //There is already an unresolved incident of the same category
  
      if (["shipment_delivered", "shipment_returned"].includes(order.shipping_status)) {
        if (!['DAMAGED', 'MISSING_PRODUCT', 'FALSE_DELIVERY'].includes(incident_category)) return [false, 'only_damaged_missing_product_false_delivery_incidents_allowed'] // only damaged, missing product and false delivery incidents are allowed when order has been delivered
      } else if (!['DELAYED', 'MISSING_REFERENCES', 'CANCELED'].includes(incident_category)) return [false, 'only_delayed_canceled_missing_references_incidents_allowed'] // only delayed and missing references incidents are allowed when order has not been delivered

      if (order.order_type === "REMOVE_STOCK") return [false, 'remove_stock_order'] //Order type is incompatible with shipping incidents
  
      return [true, null]
  }

  const getAvailableFilters = filters => {
    return filters.filter(({key}) => {
      switch (key) {

        case "with_shipping_incident":
        case "without_shipping_incident":
        case "shipping_incident_category":
        case "shipping_incident_status":
            return permissions.showIncidents

        default:
          return true;
      }
    })
  }

  return (
    <>
    <DialogView className="w-full" setOpen={setOpenDialogLabel} open={openDialogLabel} >
        <LabelView
                title={printInformation.title}
                type={printInformation.type}
                file_path = {printInformation.file_path} //{shipment.orderId}
                onCancel = {() => setOpenDialogLabel(false)}
                onPrint = {() => setOpenDialogLabel(false)}
        />
    </DialogView>
    <DialogView className="w-full" setOpen={setOpenDialogDocument} open={openDialogDocument} >
        <LabelView
                title={printInformation.title}
                type={printInformation.type}
                file_path = {printInformation.file_path} 
                onCancel = {() => setOpenDialogDocument(false)}
                onPrint = {() => {

                  setOpenDialogDocument(false)
                  const non_printed_documents = selectOrder.shipping_documents.filter((shipping_documents) => !shipping_documents.print);
                  if(non_printed_documents.length>0){
                    for (const document of non_printed_documents){ 
                        const documents = selectOrder.shipping_documents.map(sd => (sd.id === document.id ? {...sd, print:true} : sd))
                        setSelectOrder({...selectOrder, shipping_documents: documents })
                        print("Imprimir Documentos de Envío",document.file.path,document.printing_type)
                        break;
                    };
                }
                }}
        />
    </DialogView>
    {/* <DialogView setOpen={setOpenDialogBilling} open={openDialogBilling} >
        <LabelView
            title={"Factura Comercial Inernacional"}
            data={selectOrder}
            type={"Billing"}
            onCancel={() => setOpenDialogBilling(false)}
            onPrint = {() => setOpenDialogBilling(false)}
        />
    </DialogView> */}

      <PageView
        topMenu={<PageTopBar>
          <div className="text-lg font-semibold">Pedidos Enviados</div>
      </PageTopBar>}
        childrenFullWidth={true}
        topMenuFullWidth={true}
      >
        {(!warehouse && isLoadingWarehouse) || (!isPreviousDataShippingMethods && isLoadingShippingMethods) ? (
          <>
            <Loader show={true}></Loader>
          </>
        ) : isError ? (
          <>Error: {error.message}</>
        ) : isErrorShippingMethods ? (
            <>Error: {errorShippingMethods.message}</>
        ):(
          
            <>
              <div className="flex flex-col">
                  <nav className='flex relative rounded-lg border bg-white py-2 mb-2 text-gray-700'>
                      <div className="flex flex-col lg:flex-row justify-start border-l-2 lg:border-0 ml-5 lg:ml-0">
                          <div className="lg:max-w-min ml-5 my-1 lg:my-2 text-base leading-4 py-2 pl-0">
                              {i18n.t("orders.list.requiring_monitoring")}
                          </div>
                          <div className="max-w-min flex flex-col static ml-5 my-1 lg:my-2">
                              <button className="text-left whitespace-nowrap border border-gray-300 shadow-sm rounded-lg py-3 px-3 text-gray-700 font-medium hover:bg-gray-50 outline-none"
                                  onClick={() => {
                                    callFilterShortcut([{key: "is_delayed", value: true}, {key: "without_shipping_incident", value: false}])
                                  }}>
                                      <StatusPill status="delayed" statuses={[{
                                          status: "delayed",
                                          label: i18n.t("orders.filters.is_delayed"),
                                          classes: "bg-yellow-50 text-yellow-500 border-yellow-50"
                                      }]}/> + 
                                      <StatusPill status="no_incident" statuses={[{
                                          status: "no_incident",
                                          label: i18n.t("orders.filters.without_shipping_incident"),
                                          classes: "bg-gray-50 text-gray-500 border-gray-50"
                                      }]}/>
                                      {/* <span className='ml-4 text-base font-bold'>{dataSummary?.orders_data?.delayed_with_incident_orders || 0}</span> <span className='lowercase font-normal text-base'>{i18n.t("orders.incidents_bar.orders")}</span> */}
                              </button>
                          </div>
                          <div className="max-w-min flex flex-col static ml-5 my-1 lg:my-2">
                              <button className="text-left whitespace-nowrap border border-gray-300 shadow-sm rounded-lg py-3 px-3 text-gray-700 font-medium hover:bg-gray-50 outline-none"
                                  onClick={() => {
                                    callFilterShortcut([{key: "is_delayed", value: true}])
                                  }}>
                                      <StatusPill status="delayed" statuses={[{
                                          status: "delayed",
                                          label: i18n.t("orders.filters.is_delayed"),
                                          classes: "bg-yellow-50 text-yellow-500 border-yellow-50"
                                      }]}/>
                                      {/* <span className='ml-4 text-base font-bold'>{dataSummary?.orders_data?.delayed_orders || 0}</span> <span className='lowercase font-normal text-base'>{i18n.t("orders.incidents_bar.orders")}</span> */}
                              </button>
                          </div>
                          <div className="max-w-min flex flex-col static ml-5 my-1 lg:my-2">
                              <button className="text-left whitespace-nowrap border border-gray-300 shadow-sm rounded-lg py-3 px-3 text-gray-700 font-medium hover:bg-gray-50 outline-none"
                                  onClick={() => {
                                    callFilterShortcut([{key: "shipping_incident_status", value: [INCIDENT_STATUSES.IN_REVIEW, INCIDENT_STATUSES.PENDING]}, {key: "shipping_incident_category", value: []}, {key: "with_shipping_incident", value: true}])
                                  }}>
                                      <StatusPill status="unsolved_shipping_incident" statuses={[{
                                          status: "unsolved_shipping_incident",
                                          label: i18n.t("orders.filters.unsolved_shipping_incident"),
                                          classes: "bg-gray-50 text-gray-500 border-gray-50"
                                      }]}/>
                                      {/* <span className='ml-4 text-base font-bold'>{dataSummary?.orders_data?.unresolved_incident_orders || 0}</span> <span className='lowercase font-normal text-base'>{i18n.t("orders.incidents_bar.orders")}</span> */}
                              </button>
                          </div>
                          <div className="max-w-min flex flex-col static ml-5 my-1 lg:my-2">
                              <button className="text-left whitespace-nowrap border border-gray-300 shadow-sm rounded-lg py-3 px-3 text-gray-700 font-medium hover:bg-gray-50 outline-none"
                                  onClick={() => {
                                    callFilterShortcut([{key: "first_delivery_on_time", value: true}])
                                  }}>
                                      <StatusPill status="not_delayed" statuses={[{
                                          status: "not_delayed",
                                          label: i18n.t("orders.filters.first_delivery_on_time"),
                                          classes: "bg-pink-50 text-pink-500 border-pink-50"
                                      }]}/>
                                      {/* <span className='ml-4 text-base font-bold'>{dataSummary?.orders_data?.delayed_orders || 0}</span> <span className='lowercase font-normal text-base'>{i18n.t("orders.incidents_bar.orders")}</span> */}
                              </button>
                          </div>
                      </div>
                  </nav>
              </div>
              <NewTable
                deselectOrders = {deselectOrders}
                setDeselectOrders = {setDeselectOrders}
                data = {memoizedTableData}
                columns={memoizedColumns}
                showLoader={isFetching && isPreviousData}
                showPaginationOnFooter
                isFetching={isFetching}
                emptyTableText= 'No orders to show'
                paginationMeta={data?.meta ? data.meta : {per_page: 25}}
                onPaginationChange= { (page) => { 
                    setSearchParams((prev) => ({...prev, page: page})) 
                    setIsFetchShippedOrdersEnabled(true)
                }}
                onPageSizeChange = { (page) => {
                  if(page.id !== searchParams.per_page){
                    setSearchParams((prev) => ({...prev, per_page: page.id, page: 1}))
                    dispatch(setShippedOrdersListPageSize(page.id))

                    if(orders?.length > 0){
                      setIsFetchShippedOrdersEnabled(true)
                    }
                  }
                }}
                onSortChange={onSortChange}
                onSearchHandler={updateFiltersData}
                handleResetFilters={handleResetFilters}
                onClickFilterDropdown={() => {
                    LogRocket.track('ShippedOrderFilterDropdown', {
                        warehouse_id: warehouse_id
                    })
                }}
                filtersData={getAvailableFilters(filtersData)}
                hasExport={false}
                rowProps={row => ({
                  onClick: () => handlerOnClickStatus(row.values.status?.props.order)
                })}
                onSelectionChange={(rows) => {
                  setSelectedOrders(rows)
                }}
                selectable={permissions.enableMultiSelectOrders}
                applyFiltersManually={true}
                onManuallyAppliedFilters={() => setIsFetchShippedOrdersEnabled(true)}
              />
              
              <IncidentActionModal
                orders={incidentActionModalProps.orders}
                onSuccess={onActionSuccess}
                onCancel={onActionCancel}
                open={incidentActionModalProps.open}
                action={incidentActionModalProps.action}
                actionParameters={incidentActionModalProps.actionParameters}
              />
              <ShippedOrderMultiselection
                selectedOrders={selectedOrders} 
                onCreateIncidents={handleIncidentCreate}
                onSetIncidentsResolution={handleIncidentResolution}
                onSetIncidentsStatus={handleIncidentStatusUpdate}
                onSetUpdateShippingStatus={handleShippingStatusUpdate}
                onDeselectOrders={handleDeselectOrders}
                enableCreateIncident={permissions.enableCreateIncident}
                enableUpdateIncidentStatus={permissions.enableUpdateIncidentStatus}
                enableUpdateShippingStatus={permissions.enableUpdateShippingStatus}
              />
            </>

        )}
      </PageView>
      <Switch>
        <Route exact path={SHIPPED_ORDER_DETAIL}>
          <SlidePanel title="Ver Orden" referrer={ORDERS_SHIPPED.replace(':warehouse_id', warehouse_id)}>
            <ShowOrderContainer showIncidents={permissions.showIncidents} showCubboLastmileEvents={user.roles?.some(role => ["ROLE_SUPER_ADMIN"].includes(role))} />
          </SlidePanel>
        </Route>
      </Switch>
    </>
  )
}
