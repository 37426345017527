import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { cloneDeep } from "lodash"
import { CheckCircleIcon, ExclamationCircleIcon } from "@heroicons/react/outline"

import RangeBillingPriceConfigTable from "../RangeBillingPriceConfigTable"
import { NATIONAL_MX_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES, NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES, SERVICE_TYPES } from "../constants"
import { findOrCreateBillingService, getCurrency, getShippingLabelCategories } from "../utils"
import { UNITS_IDS } from "../RangeBillingPriceConfigTable/constants"

const NationalShippingLabelServices = ({storeBillingServices, onUpdateStoreBillingServices, onIsValid, storeId, warehouse}) => {
    const { i18n } = useTranslation()
    const tableHeaders = [
        {label: i18n.t("billing.table_headers.from_weight"), id: "from"},
        {label: i18n.t("billing.table_headers.to_weight"), id: "to"},
        {label: i18n.t("billing.table_headers.price"), id: "price"},
        {label: i18n.t("billing.table_headers.unit"),id: "unit"},
        {label: null,id: 'actions'}
    ]

    const serviceTypeCategories = Object.values(getShippingLabelCategories(warehouse?.country))


    //MX
    const [isSuperSaverValid, setIsSuperSaverValid] = useState(false)
    const [isLocalValid, setIsLocalValid] = useState(false)
    const [isPremiumValid, setIsPremiumValid] = useState(false)
    const [isEconomyValid, setIsEconomyValid] = useState(false)
    const [isPickupValid, setIsPickupValid] = useState(false)

    const [isSuperSaverExtendedZoneValid, setIsSuperSaverExtendedZoneValid] = useState(false)
    const [isLocalExtendedZoneValid, setIsLocalExtendedZoneValid] = useState(false)
    const [isPremiumExtendedZoneValid, setIsPremiumExtendedZoneValid] = useState(false)
    const [isEconomyExtendedZoneValid, setIsEconomyExtendedZoneValid] = useState(false)

    //BR
    const [isSPCapitalValid, setisSPCapitalValid] = useState(false)
    const [isSPInteriorValid, setisSPInteriorValid] = useState(false)
    const [isRJCapitalValid, setisRJCapitalValid] = useState(false)
    const [isRJInteriorValid, setisRJInteriorValid] = useState(false)
    const [isMGCapitalValid, setIsMGCapitalValid] = useState(false)
    const [isMGInteriorValid, setIsMGInteriorValid] = useState(false)
    const [isESCapitalValid, setIsESCapitalValid] = useState(false)
    const [isESInteriorValid, setIsESInteriorValid] = useState(false)
    const [isBACapitalValid, setIsBACapitalValid] = useState(false)
    const [isBAInteriorValid, setIsBAInteriorValid] = useState(false)
    const [isSECapitalValid, setIsSECapitalValid] = useState(false)
    const [isSEInteriorValid, setIsSEInteriorValid] = useState(false)
    const [isPECapitalValid, setIsPECapitalValid] = useState(false)
    const [isPEInteriorValid, setIsPEInteriorValid] = useState(false)
    const [isALCapitalValid, setIsALCapitalValid] = useState(false)
    const [isALInteriorValid, setIsALInteriorValid] = useState(false)
    const [isPBCapitalValid, setIsPBCapitalValid] = useState(false)
    const [isPBInteriorValid, setIsPBInteriorValid] = useState(false)
    const [isRNCapitalValid, setIsRNCapitalValid] = useState(false)
    const [isRNInteriorValid, setIsRNInteriorValid] = useState(false)
    const [isCECapitalValid, setIsCECapitalValid] = useState(false)
    const [isCEInteriorValid, setIsCEInteriorValid] = useState(false)
    const [isPICapitalValid, setIsPICapitalValid] = useState(false)
    const [isPIInteriorValid, setIsPIInteriorValid] = useState(false)
    const [isMACapitalValid, setIsMACapitalValid] = useState(false)
    const [isMAInteriorValid, setIsMAInteriorValid] = useState(false)
    const [isPACapitalValid, setIsPACapitalValid] = useState(false)
    const [isPAInteriorValid, setIsPAInteriorValid] = useState(false)
    const [isAPCapitalValid, setIsAPCapitalValid] = useState(false)
    const [isAPInteriorValid, setIsAPInteriorValid] = useState(false)
    const [isAMCapitalValid, setIsAMCapitalValid] = useState(false)
    const [isAMInteriorValid, setIsAMInteriorValid] = useState(false)
    const [isRRCapitalValid, setIsRRCapitalValid] = useState(false)
    const [isRRInteriorValid, setIsRRInteriorValid] = useState(false)
    const [isAMInteriorValid2, setIsAMInteriorValid2] = useState(false)
    const [isACCapitalValid, setIsACCapitalValid] = useState(false)
    const [isACInteriorValid, setIsACInteriorValid] = useState(false)
    const [isDFCapitalValid, setIsDFCapitalValid] = useState(false)
    const [isDFInteriorValid, setIsDFInteriorValid] = useState(false)
    const [isGOCapitalValid, setIsGOCapitalValid] = useState(false)
    const [isDFInteriorValid2, setIsDFInteriorValid2] = useState(false)
    const [isGOCapitalValid2, setIsGOCapitalValid2] = useState(false)
    const [isGOInteriorValid, setIsGOInteriorValid] = useState(false)
    const [isROCapitalValid, setIsROCapitalValid] = useState(false)
    const [isROInteriorValid, setIsROInteriorValid] = useState(false)
    const [isTOCapitalValid, setIsTOCapitalValid] = useState(false)
    const [isTOInteriorValid, setIsTOInteriorValid] = useState(false)
    const [isMTCapitalValid, setIsMTCapitalValid] = useState(false)
    const [isMTInteriorValid, setIsMTInteriorValid] = useState(false)
    const [isROInteriorValid2, setIsROInteriorValid2] = useState(false)
    const [isROInteriorValid3, setIsROInteriorValid3] = useState(false)
    const [isMSCapitalValid, setIsMSCapitalValid] = useState(false)
    const [isMSInteriorValid, setIsMSInteriorValid] = useState(false)
    const [isPRCapitalValid, setIsPRCapitalValid] = useState(false)
    const [isPRInteriorValid, setIsPRInteriorValid] = useState(false)
    const [isSCCapitalValid, setIsSCCapitalValid] = useState(false)
    const [isSCInteriorValid, setIsSCInteriorValid] = useState(false)
    const [isRSCapitalValid, setIsRSCapitalValid] = useState(false)
    const [isRSInteriorValid, setIsRSInteriorValid] = useState(false)

    const [isSPCapitalExtendedZoneValid, setisSPCapitalExtendedZoneValid] = useState(false)
    const [isSPInteriorExtendedZoneValid, setisSPInteriorExtendedZoneValid] = useState(false)
    const [isRJCapitalExtendedZoneValid, setisRJCapitalExtendedZoneValid] = useState(false)
    const [isRJInteriorExtendedZoneValid, setisRJInteriorExtendedZoneValid] = useState(false)
    const [isMGCapitalExtendedZoneValid, setIsMGCapitalExtendedZoneValid] = useState(false)
    const [isMGInteriorExtendedZoneValid, setIsMGInteriorExtendedZoneValid] = useState(false)
    const [isESCapitalExtendedZoneValid, setIsESCapitalExtendedZoneValid] = useState(false)
    const [isESInteriorExtendedZoneValid, setIsESInteriorExtendedZoneValid] = useState(false)
    const [isBACapitalExtendedZoneValid, setIsBACapitalExtendedZoneValid] = useState(false)
    const [isBAInteriorExtendedZoneValid, setIsBAInteriorExtendedZoneValid] = useState(false)
    const [isSECapitalExtendedZoneValid, setIsSECapitalExtendedZoneValid] = useState(false)
    const [isSEInteriorExtendedZoneValid, setIsSEInteriorExtendedZoneValid] = useState(false)
    const [isPECapitalExtendedZoneValid, setIsPECapitalExtendedZoneValid] = useState(false)
    const [isPEInteriorExtendedZoneValid, setIsPEInteriorExtendedZoneValid] = useState(false)
    const [isALCapitalExtendedZoneValid, setIsALCapitalExtendedZoneValid] = useState(false)
    const [isALInteriorExtendedZoneValid, setIsALInteriorExtendedZoneValid] = useState(false)
    const [isPBCapitalExtendedZoneValid, setIsPBCapitalExtendedZoneValid] = useState(false)
    const [isPBInteriorExtendedZoneValid, setIsPBInteriorExtendedZoneValid] = useState(false)
    const [isRNCapitalExtendedZoneValid, setIsRNCapitalExtendedZoneValid] = useState(false)
    const [isRNInteriorExtendedZoneValid, setIsRNInteriorExtendedZoneValid] = useState(false)
    const [isCECapitalExtendedZoneValid, setIsCECapitalExtendedZoneValid] = useState(false)
    const [isCEInteriorExtendedZoneValid, setIsCEInteriorExtendedZoneValid] = useState(false)
    const [isPICapitalExtendedZoneValid, setIsPICapitalExtendedZoneValid] = useState(false)
    const [isPIInteriorExtendedZoneValid, setIsPIInteriorExtendedZoneValid] = useState(false)
    const [isMACapitalExtendedZoneValid, setIsMACapitalExtendedZoneValid] = useState(false)
    const [isMAInteriorExtendedZoneValid, setIsMAInteriorExtendedZoneValid] = useState(false)
    const [isPACapitalExtendedZoneValid, setIsPACapitalExtendedZoneValid] = useState(false)
    const [isPAInteriorExtendedZoneValid, setIsPAInteriorExtendedZoneValid] = useState(false)
    const [isAPCapitalExtendedZoneValid, setIsAPCapitalExtendedZoneValid] = useState(false)
    const [isAPInteriorExtendedZoneValid, setIsAPInteriorExtendedZoneValid] = useState(false)
    const [isAMCapitalExtendedZoneValid, setIsAMCapitalExtendedZoneValid] = useState(false)
    const [isAMInteriorExtendedZoneValid, setIsAMInteriorExtendedZoneValid] = useState(false)
    const [isRRCapitalExtendedZoneValid, setIsRRCapitalExtendedZoneValid] = useState(false)
    const [isRRInteriorExtendedZoneValid, setIsRRInteriorExtendedZoneValid] = useState(false)
    const [isAMInteriorExtendedZoneValid2, setIsAMInteriorExtendedZoneValid2] = useState(false)
    const [isACCapitalExtendedZoneValid, setIsACCapitalExtendedZoneValid] = useState(false)
    const [isACInteriorExtendedZoneValid, setIsACInteriorExtendedZoneValid] = useState(false)
    const [isDFCapitalExtendedZoneValid, setIsDFCapitalExtendedZoneValid] = useState(false)
    const [isDFInteriorExtendedZoneValid, setIsDFInteriorExtendedZoneValid] = useState(false)
    const [isGOCapitalExtendedZoneValid, setIsGOCapitalExtendedZoneValid] = useState(false)
    const [isDFInteriorExtendedZoneValid2, setIsDFInteriorExtendedZoneValid2] = useState(false)
    const [isGOCapitalExtendedZoneValid2, setIsGOCapitalExtendedZoneValid2] = useState(false)
    const [isGOInteriorExtendedZoneValid, setIsGOInteriorExtendedZoneValid] = useState(false)
    const [isROCapitalExtendedZoneValid, setIsROCapitalExtendedZoneValid] = useState(false)
    const [isROInteriorExtendedZoneValid, setIsROInteriorExtendedZoneValid] = useState(false)
    const [isTOCapitalExtendedZoneValid, setIsTOCapitalExtendedZoneValid] = useState(false)
    const [isTOInteriorExtendedZoneValid, setIsTOInteriorExtendedZoneValid] = useState(false)
    const [isMTCapitalExtendedZoneValid, setIsMTCapitalExtendedZoneValid] = useState(false)
    const [isMTInteriorExtendedZoneValid, setIsMTInteriorExtendedZoneValid] = useState(false)
    const [isROInteriorExtendedZoneValid2, setIsROInteriorExtendedZoneValid2] = useState(false)
    const [isROInteriorExtendedZoneValid3, setIsROInteriorExtendedZoneValid3] = useState(false)
    const [isMSCapitalExtendedZoneValid, setIsMSCapitalExtendedZoneValid] = useState(false)
    const [isMSInteriorExtendedZoneValid, setIsMSInteriorExtendedZoneValid] = useState(false)
    const [isPRCapitalExtendedZoneValid, setIsPRCapitalExtendedZoneValid] = useState(false)
    const [isPRInteriorExtendedZoneValid, setIsPRInteriorExtendedZoneValid] = useState(false)
    const [isSCCapitalExtendedZoneValid, setIsSCCapitalExtendedZoneValid] = useState(false)
    const [isSCInteriorExtendedZoneValid, setIsSCInteriorExtendedZoneValid] = useState(false)
    const [isRSCapitalExtendedZoneValid, setIsRSCapitalExtendedZoneValid] = useState(false)
    const [isRSInteriorExtendedZoneValid, setIsRSInteriorExtendedZoneValid] = useState(false)
  

    const shippingLabelServices = serviceTypeCategories.map(serviceTypeCategory => {
        return findOrCreateBillingService({serviceType: SERVICE_TYPES.SERVICE_SHIPPING_LABEL, serviceTypeCategory: serviceTypeCategory, billingServices: storeBillingServices, currency: getCurrency(warehouse?.country), storeId: storeId, warehouseId: warehouse?.id})
    })

    const extendedZoneServices = serviceTypeCategories.map(serviceTypeCategory => {
        return findOrCreateBillingService({serviceType: SERVICE_TYPES.SERVICE_SHIPPING_LABEL_EXTENDED_ZONE, serviceTypeCategory: serviceTypeCategory, billingServices: storeBillingServices, currency: getCurrency(warehouse?.country), storeId: storeId, warehouseId: warehouse?.id})
    })

    const pickupService = findOrCreateBillingService({serviceType: SERVICE_TYPES.SERVICE_SHIPPING_LABEL_PICKUP, billingServices: storeBillingServices, currency: getCurrency(warehouse?.country), storeId: storeId, warehouseId: warehouse?.id})

    const shippingLabelTablesData = shippingLabelServices?.map(billingService => {
        const data = billingService?.price_configuration.map(priceRange => {
            return {
                from: {value: priceRange.from, isDisabled: true, scale: 2},
                to: {value: priceRange.to, scale: 2},
                price: {value: priceRange.price},
                price_mode: {value: priceRange.price_mode},
                serviceTypeCategory: {value: priceRange.service_type_category},
                excess: {value: priceRange.excess},
                range_exclusion: {value: priceRange.range_exclusion},
            }
        })
        return {category: billingService.service_type_category, data: data}
    })

    const extendedZoneTablesData = extendedZoneServices?.map(billingService => {
        const data = billingService?.price_configuration.map(priceRange => {
            return {
                from: {value: priceRange.from, isDisabled: true, scale: 2},
                to: {value: priceRange.to, isDisabled: true, scale: 2},
                price: {value: priceRange.price},
                price_mode: {value: priceRange.price_mode},
                serviceTypeCategory: {value: priceRange.service_type_category},
                excess: {value: priceRange.excess},
                range_exclusion: {value: priceRange.range_exclusion},
            }
        })
        return {category: billingService.service_type_category, data: data}
    })

    const pickupTableData = pickupService?.price_configuration.map(priceRange => {
        const data = {
                from: {value: priceRange.from, isDisabled: true, scale: 2},
                to: {value: priceRange.to, isDisabled: true, scale: 2},
                price: {value: priceRange.price},
                price_mode: {value: priceRange.price_mode},
                serviceTypeCategory: {value: priceRange.service_type_category},
                excess: {value: priceRange.excess},
                range_exclusion: {value: priceRange.range_exclusion},
            }
        return data
    })

    const unitOptionsLabels = {
        TOTAL: i18n.t("billing.national_shipping_label_services.unit_options.TOTAL"),
        EXCESS_PER_UNIT: i18n.t("billing.national_shipping_label_services.unit_options.EXCESS_PER_UNIT")
    }

    const pickupOptionsLabels = {
        TOTAL: i18n.t("billing.national_shipping_label_services.pickup_unit_options.TOTAL"),
    }

    const handleOnUpdateData = (newTableData, service) => {
        let newStoreBillingServices = cloneDeep(storeBillingServices)

        const newPriceConfiguration = newTableData.map(priceRangeData => {
            return {
                from: priceRangeData.from.value,
                to: priceRangeData.to.value,
                price: priceRangeData.price.value,
                price_mode: priceRangeData.price_mode.value,
                excess: priceRangeData.excess.value,
                range_exclusion: priceRangeData.range_exclusion.value
            }
        })

        const serviceExists = newStoreBillingServices.some(({service_type, service_type_category}) => (
            service_type === service.service_type && service_type_category === service.service_type_category
        ))

        if(serviceExists){
            newStoreBillingServices = newStoreBillingServices.map(billingService => {
                if(billingService.service_type === service.service_type && billingService.service_type_category === service.service_type_category){
                    billingService.price_configuration = newPriceConfiguration
                }
                return billingService
            })
        } else{
            const newService = cloneDeep(service)
            newService.price_configuration = newPriceConfiguration
            newStoreBillingServices.push(newService)
        }

        onUpdateStoreBillingServices(newStoreBillingServices)
    }

    const handleOnIsValid = (serviceType, serviceTypeCategory, isValid) => {
        if(warehouse?.country === 'MX') {
            if(serviceType === SERVICE_TYPES.SERVICE_SHIPPING_LABEL){
                switch(serviceTypeCategory){
                    case NATIONAL_MX_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.SUPER_SAVER:
                        setIsSuperSaverValid(isValid)
                        break
                    case NATIONAL_MX_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.LOCAL:
                        setIsLocalValid(isValid)
                        break
                    case NATIONAL_MX_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.PREMIUM:
                        setIsPremiumValid(isValid)
                        break
                    case NATIONAL_MX_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.ECONOMY:
                        setIsEconomyValid(isValid)
                        break
                    default:
                        break
                }
            } else if(serviceType === SERVICE_TYPES.SERVICE_SHIPPING_LABEL_EXTENDED_ZONE){
                switch(serviceTypeCategory){
                    case NATIONAL_MX_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.SUPER_SAVER:
                        setIsSuperSaverExtendedZoneValid(isValid)
                        break
                    case NATIONAL_MX_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.LOCAL:
                        setIsLocalExtendedZoneValid(isValid)
                        break
                    case NATIONAL_MX_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.PREMIUM:
                        setIsPremiumExtendedZoneValid(isValid)
                        break
                    case NATIONAL_MX_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.ECONOMY:
                        setIsEconomyExtendedZoneValid(isValid)
                        break
                    default:
                        break
                }
            } else if(serviceType === SERVICE_TYPES.SERVICE_SHIPPING_LABEL_PICKUP){
                setIsPickupValid(isValid)
            }
        } else if (warehouse?.country === 'BR') {
            if (serviceType === SERVICE_TYPES.SERVICE_SHIPPING_LABEL) {
                switch (serviceTypeCategory) {
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.SP_CAP:
                        setisSPCapitalValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.SP_INT:
                        setisSPInteriorValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.RJ_CAP:
                        setisRJCapitalValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.RJ_INT:
                        setisRJInteriorValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.MG_CAP:
                        setIsMGCapitalValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.MG_INT:
                        setIsMGInteriorValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.ES_CAP:
                        setIsESCapitalValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.ES_INT:
                        setIsESInteriorValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.BA_CAP:
                        setIsBACapitalValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.BA_INT:
                        setIsBAInteriorValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.SE_CAP:
                        setIsSECapitalValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.SE_INT:
                        setIsSEInteriorValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.PE_CAP:
                        setIsPECapitalValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.PE_INT:
                        setIsPEInteriorValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.AL_CAP:
                        setIsALCapitalValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.AL_INT:
                        setIsALInteriorValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.PB_CAP:
                        setIsPBCapitalValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.PB_INT:
                        setIsPBInteriorValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.RN_CAP:
                        setIsRNCapitalValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.RN_INT:
                        setIsRNInteriorValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.CE_CAP:
                        setIsCECapitalValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.CE_INT:
                        setIsCEInteriorValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.PI_CAP:
                        setIsPICapitalValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.PI_INT:
                        setIsPIInteriorValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.MA_CAP:
                        setIsMACapitalValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.MA_INT:
                        setIsMAInteriorValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.PA_CAP:
                        setIsPACapitalValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.PA_INT:
                        setIsPAInteriorValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.AP_CAP:
                        setIsAPCapitalValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.AP_INT:
                        setIsAPInteriorValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.AM_CAP:
                        setIsAMCapitalValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.AM_INT:
                        setIsAMInteriorValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.RR_CAP:
                        setIsRRCapitalValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.RR_INT:
                        setIsRRInteriorValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.AM_INT_2:
                        setIsAMInteriorValid2(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.AC_CAP:
                        setIsACCapitalValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.AC_INT:
                        setIsACInteriorValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.DF_CAP:
                        setIsDFCapitalValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.DF_INT:
                        setIsDFInteriorValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.GO_CAP:
                        setIsGOCapitalValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.DF_INT_2:
                        setIsDFInteriorValid2(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.GO_CAP_2:
                        setIsGOCapitalValid2(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.GO_INT:
                        setIsGOInteriorValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.RO_CAP:
                        setIsROCapitalValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.RO_INT:
                        setIsROInteriorValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.TO_CAP:
                        setIsTOCapitalValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.TO_INT:
                        setIsTOInteriorValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.MT_CAP:
                        setIsMTCapitalValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.MT_INT:
                        setIsMTInteriorValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.RO_INT_2:
                        setIsROInteriorValid2(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.RO_INT_3:
                        setIsROInteriorValid3(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.MS_CAP:
                        setIsMSCapitalValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.MS_INT:
                        setIsMSInteriorValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.PR_CAP:
                        setIsPRCapitalValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.PR_INT:
                        setIsPRInteriorValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.SC_CAP:
                        setIsSCCapitalValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.SC_INT:
                        setIsSCInteriorValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.RS_CAP:
                        setIsRSCapitalValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.RS_INT:
                        setIsRSInteriorValid(isValid)
                        break
                    default:
                        break
                }
            } else if (serviceType === SERVICE_TYPES.SERVICE_SHIPPING_LABEL_EXTENDED_ZONE) {
                switch (serviceTypeCategory) {
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.SP_CAP:
                        setisSPCapitalExtendedZoneValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.SP_INT:
                        setisSPInteriorExtendedZoneValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.RJ_CAP:
                        setisRJCapitalExtendedZoneValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.RJ_INT:
                        setisRJInteriorExtendedZoneValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.MG_CAP:
                        setIsMGCapitalExtendedZoneValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.MG_INT:
                        setIsMGInteriorExtendedZoneValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.ES_CAP:
                        setIsESCapitalExtendedZoneValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.ES_INT:
                        setIsESInteriorExtendedZoneValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.BA_CAP:
                        setIsBACapitalExtendedZoneValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.BA_INT:
                        setIsBAInteriorExtendedZoneValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.SE_CAP:
                        setIsSECapitalExtendedZoneValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.SE_INT:
                        setIsSEInteriorExtendedZoneValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.PE_CAP:
                        setIsPECapitalExtendedZoneValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.PE_INT:
                        setIsPEInteriorExtendedZoneValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.AL_CAP:
                        setIsALCapitalExtendedZoneValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.AL_INT:
                        setIsALInteriorExtendedZoneValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.PB_CAP:
                        setIsPBCapitalExtendedZoneValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.PB_INT:
                        setIsPBInteriorExtendedZoneValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.RN_CAP:
                        setIsRNCapitalExtendedZoneValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.RN_INT:
                        setIsRNInteriorExtendedZoneValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.CE_CAP:
                        setIsCECapitalExtendedZoneValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.CE_INT:
                        setIsCEInteriorExtendedZoneValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.PI_CAP:
                        setIsPICapitalExtendedZoneValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.PI_INT:
                        setIsPIInteriorExtendedZoneValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.MA_CAP:
                        setIsMACapitalExtendedZoneValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.MA_INT:
                        setIsMAInteriorExtendedZoneValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.PA_CAP:
                        setIsPACapitalExtendedZoneValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.PA_INT:
                        setIsPAInteriorExtendedZoneValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.AP_CAP:
                        setIsAPCapitalExtendedZoneValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.AP_INT:
                        setIsAPInteriorExtendedZoneValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.AM_CAP:
                        setIsAMCapitalExtendedZoneValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.AM_INT:
                        setIsAMInteriorExtendedZoneValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.RR_CAP:
                        setIsRRCapitalExtendedZoneValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.RR_INT:
                        setIsRRInteriorExtendedZoneValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.AM_INT_2:
                        setIsAMInteriorExtendedZoneValid2(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.AC_CAP:
                        setIsACCapitalExtendedZoneValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.AC_INT:
                        setIsACInteriorExtendedZoneValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.DF_CAP:
                        setIsDFCapitalExtendedZoneValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.DF_INT:
                        setIsDFInteriorExtendedZoneValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.GO_CAP:
                        setIsGOCapitalExtendedZoneValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.DF_INT_2:
                        setIsDFInteriorExtendedZoneValid2(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.GO_CAP_2:
                        setIsGOCapitalExtendedZoneValid2(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.GO_INT:
                        setIsGOInteriorExtendedZoneValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.RO_CAP:
                        setIsROCapitalExtendedZoneValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.RO_INT:
                        setIsROInteriorExtendedZoneValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.TO_CAP:
                        setIsTOCapitalExtendedZoneValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.TO_INT:
                        setIsTOInteriorExtendedZoneValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.MT_CAP:
                        setIsMTCapitalExtendedZoneValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.MT_INT:
                        setIsMTInteriorExtendedZoneValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.RO_INT_2:
                        setIsROInteriorExtendedZoneValid2(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.RO_INT_3:
                        setIsROInteriorExtendedZoneValid3(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.MS_CAP:
                        setIsMSCapitalExtendedZoneValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.MS_INT:
                        setIsMSInteriorExtendedZoneValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.PR_CAP:
                        setIsPRCapitalExtendedZoneValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.PR_INT:
                        setIsPRInteriorExtendedZoneValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.SC_CAP:
                        setIsSCCapitalExtendedZoneValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.SC_INT:
                        setIsSCInteriorExtendedZoneValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.RS_CAP:
                        setIsRSCapitalExtendedZoneValid(isValid)
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.RS_INT:
                        setIsRSInteriorExtendedZoneValid(isValid)
                        break
                    default:
                        break
                }
            } else if (serviceType === SERVICE_TYPES.SERVICE_SHIPPING_LABEL_PICKUP) {
                setIsPickupValid(isValid)
            }
        }
    }

    const validateServiceStatus = (serviceType, serviceTypeCategory) => {
        let isValid = false
        if(warehouse?.country === 'MX') {
            if(serviceType === SERVICE_TYPES.SERVICE_SHIPPING_LABEL){
                switch(serviceTypeCategory){
                    case NATIONAL_MX_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.SUPER_SAVER:
                        isValid = isSuperSaverValid
                        break
                    case NATIONAL_MX_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.LOCAL:
                        isValid = isLocalValid
                        break
                    case NATIONAL_MX_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.PREMIUM:
                        isValid = isPremiumValid
                        break
                    case NATIONAL_MX_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.ECONOMY:
                        isValid = isEconomyValid
                        break
                    default:
                        break
                }
            } else if(serviceType === SERVICE_TYPES.SERVICE_SHIPPING_LABEL_EXTENDED_ZONE){
                switch(serviceTypeCategory){
                    case NATIONAL_MX_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.SUPER_SAVER:
                        isValid = isSuperSaverExtendedZoneValid
                        break
                    case NATIONAL_MX_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.LOCAL:
                        isValid = isLocalExtendedZoneValid
                        break
                    case NATIONAL_MX_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.PREMIUM:
                        isValid = isPremiumExtendedZoneValid
                        break
                    case NATIONAL_MX_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.ECONOMY:
                        isValid = isEconomyExtendedZoneValid
                        break
                    default:
                        break
                }
            } else if(serviceType === SERVICE_TYPES.SERVICE_SHIPPING_LABEL_PICKUP){
                isValid = isPickupValid
            }
        } else if (warehouse?.country === 'BR') {
            if (serviceType === SERVICE_TYPES.SERVICE_SHIPPING_LABEL) {
                switch (serviceTypeCategory) {
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.SP_CAP:
                        isValid = isSPCapitalValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.SP_INT:
                        isValid = isSPInteriorValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.RJ_CAP:
                        isValid = isRJCapitalValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.RJ_INT:
                        isValid = isRJInteriorValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.MG_CAP:
                        isValid = isMGCapitalValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.MG_INT:
                        isValid = isMGInteriorValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.ES_CAP:
                        isValid = isESCapitalValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.ES_INT:
                        isValid = isESInteriorValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.BA_CAP:
                        isValid = isBACapitalValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.BA_INT:
                        isValid = isBAInteriorValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.SE_CAP:
                        isValid = isSECapitalValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.SE_INT:
                        isValid = isSEInteriorValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.PE_CAP:
                        isValid = isPECapitalValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.PE_INT:
                        isValid = isPEInteriorValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.AL_CAP:
                        isValid = isALCapitalValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.AL_INT:
                        isValid = isALInteriorValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.PB_CAP:
                        isValid = isPBCapitalValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.PB_INT:
                        isValid = isPBInteriorValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.RN_CAP:
                        isValid = isRNCapitalValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.RN_INT:
                        isValid = isRNInteriorValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.CE_CAP:
                        isValid = isCECapitalValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.CE_INT:
                        isValid = isCEInteriorValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.PI_CAP:
                        isValid = isPICapitalValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.PI_INT:
                        isValid = isPIInteriorValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.MA_CAP:
                        isValid = isMACapitalValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.MA_INT:
                        isValid = isMAInteriorValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.PA_CAP:
                        isValid = isPACapitalValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.PA_INT:
                        isValid = isPAInteriorValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.AP_CAP:
                        isValid = isAPCapitalValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.AP_INT:
                        isValid = isAPInteriorValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.AM_CAP:
                        isValid = isAMCapitalValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.AM_INT:
                        isValid = isAMInteriorValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.RR_CAP:
                        isValid = isRRCapitalValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.RR_INT:
                        isValid = isRRInteriorValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.AM_INT_2:
                        isValid = isAMInteriorValid2
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.AC_CAP:
                        isValid = isACCapitalValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.AC_INT:
                        isValid = isACInteriorValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.DF_CAP:
                        isValid = isDFCapitalValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.DF_INT:
                        isValid = isDFInteriorValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.GO_CAP:
                        isValid = isGOCapitalValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.DF_INT_2:
                        isValid = isDFInteriorValid2
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.GO_CAP_2:
                        isValid = isGOCapitalValid2
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.GO_INT:
                        isValid = isGOInteriorValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.RO_CAP:
                        isValid = isROCapitalValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.RO_INT:
                        isValid = isROInteriorValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.TO_CAP:
                        isValid = isTOCapitalValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.TO_INT:
                        isValid = isTOInteriorValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.MT_CAP:
                        isValid = isMTCapitalValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.MT_INT:
                        isValid = isMTInteriorValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.RO_INT_2:
                        isValid = isROInteriorValid2
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.RO_INT_3:
                        isValid = isROInteriorValid3
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.MS_CAP:
                        isValid = isMSCapitalValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.MS_INT:
                        isValid = isMSInteriorValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.PR_CAP:
                        isValid = isPRCapitalValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.PR_INT:
                        isValid = isPRInteriorValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.SC_CAP:
                        isValid = isSCCapitalValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.SC_INT:
                        isValid = isSCInteriorValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.RS_CAP:
                        isValid = isRSCapitalValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.RS_INT:
                        isValid = isRSInteriorValid
                        break
                    default:
                        break
                }
            } else if (serviceType === SERVICE_TYPES.SERVICE_SHIPPING_LABEL_EXTENDED_ZONE) {
                switch (serviceTypeCategory) {
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.SP_CAP:
                        isValid = isSPCapitalExtendedZoneValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.SP_INT:
                        isValid = isSPInteriorExtendedZoneValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.RJ_CAP:
                        isValid = isRJCapitalExtendedZoneValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.RJ_INT:
                        isValid = isRJInteriorExtendedZoneValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.MG_CAP:
                        isValid = isMGCapitalExtendedZoneValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.MG_INT:
                        isValid = isMGInteriorExtendedZoneValid // Ensure this matches your desired logic
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.ES_CAP:
                        isValid = isESCapitalExtendedZoneValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.ES_INT:
                        isValid = isESInteriorExtendedZoneValid // Ensure this matches your desired logic
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.BA_CAP:
                        isValid = isBACapitalExtendedZoneValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.BA_INT:
                        isValid = isBAInteriorExtendedZoneValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.SE_CAP:
                        isValid = isSECapitalExtendedZoneValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.SE_INT:
                        isValid = isSEInteriorExtendedZoneValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.PE_CAP:
                        isValid = isPECapitalExtendedZoneValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.PE_INT:
                        isValid = isPEInteriorExtendedZoneValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.AL_CAP:
                        isValid = isALCapitalExtendedZoneValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.AL_INT:
                        isValid = isALInteriorExtendedZoneValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.PB_CAP:
                        isValid = isPBCapitalExtendedZoneValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.PB_INT:
                        isValid = isPBInteriorExtendedZoneValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.RN_CAP:
                        isValid = isRNCapitalExtendedZoneValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.RN_INT:
                        isValid = isRNInteriorExtendedZoneValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.CE_CAP:
                        isValid = isCECapitalExtendedZoneValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.CE_INT:
                        isValid = isCEInteriorExtendedZoneValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.PI_CAP:
                        isValid = isPICapitalExtendedZoneValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.PI_INT:
                        isValid = isPIInteriorExtendedZoneValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.MA_CAP:
                        isValid = isMACapitalExtendedZoneValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.MA_INT:
                        isValid = isMAInteriorExtendedZoneValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.PA_CAP:
                        isValid = isPACapitalExtendedZoneValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.PA_INT:
                        isValid = isPAInteriorExtendedZoneValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.AP_CAP:
                        isValid = isAPCapitalExtendedZoneValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.AP_INT:
                        isValid = isAPInteriorExtendedZoneValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.AM_CAP:
                        isValid = isAMCapitalExtendedZoneValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.AM_INT:
                        isValid = isAMInteriorExtendedZoneValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.RR_CAP:
                        isValid = isRRCapitalExtendedZoneValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.RR_INT:
                        isValid = isRRInteriorExtendedZoneValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.AM_INT_2:
                        isValid = isAMInteriorExtendedZoneValid2
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.AC_CAP:
                        isValid = isACCapitalExtendedZoneValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.AC_INT:
                        isValid = isACInteriorExtendedZoneValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.DF_CAP:
                        isValid = isDFCapitalExtendedZoneValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.DF_INT:
                        isValid = isDFInteriorExtendedZoneValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.GO_CAP:
                        isValid = isGOCapitalExtendedZoneValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.DF_INT_2:
                        isValid = isDFInteriorExtendedZoneValid2
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.GO_CAP_2:
                        isValid = isGOCapitalExtendedZoneValid2
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.GO_INT:
                        isValid = isGOInteriorExtendedZoneValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.RO_CAP:
                        isValid = isROCapitalExtendedZoneValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.RO_INT:
                        isValid = isROInteriorExtendedZoneValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.TO_CAP:
                        isValid = isTOCapitalExtendedZoneValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.TO_INT:
                        isValid = isTOInteriorExtendedZoneValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.MT_CAP:
                        isValid = isMTCapitalExtendedZoneValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.MT_INT:
                        isValid = isMTInteriorExtendedZoneValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.RO_INT_2:
                        isValid = isROInteriorExtendedZoneValid2
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.RO_INT_3:
                        isValid = isROInteriorExtendedZoneValid3
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.MS_CAP:
                        isValid = isMSCapitalExtendedZoneValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.MS_INT:
                        isValid = isMSInteriorExtendedZoneValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.PR_CAP:
                        isValid = isPRCapitalExtendedZoneValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.PR_INT:
                        isValid = isPRInteriorExtendedZoneValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.SC_CAP:
                        isValid = isSCCapitalExtendedZoneValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.SC_INT:
                        isValid = isSCInteriorExtendedZoneValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.RS_CAP:
                        isValid = isRSCapitalExtendedZoneValid
                        break
                    case NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.RS_INT:
                        isValid = isRSInteriorExtendedZoneValid
                        break
                    default:
                        break
                }
            } else if (serviceType === SERVICE_TYPES.SERVICE_SHIPPING_LABEL_PICKUP) {
                isValid = isPickupValid
            }
        }        

        return isValid
    }

    useEffect(() => {
        let isValid = false
        if (warehouse?.country === 'MX') {
            isValid = isSuperSaverValid && isLocalValid && isPremiumValid && isEconomyValid && isPickupValid && 
                      isSuperSaverExtendedZoneValid && isLocalExtendedZoneValid && isPremiumExtendedZoneValid && isEconomyExtendedZoneValid
        } else if (warehouse?.country === 'BR') {
            isValid = isSPCapitalValid && isSPInteriorValid && isRJCapitalValid && isRJInteriorValid && 
                      isMGCapitalValid && isMGInteriorValid && isESCapitalValid && isESInteriorValid && isBACapitalValid && isBAInteriorValid && 
                      isSECapitalValid && isSEInteriorValid && isPECapitalValid && isPEInteriorValid &&
                      isALCapitalValid && isALInteriorValid && isPBCapitalValid && isPBInteriorValid &&
                      isRNCapitalValid && isRNInteriorValid && isCECapitalValid && isCEInteriorValid &&
                      isPICapitalValid && isPIInteriorValid && isMACapitalValid && isMAInteriorValid &&
                      isPACapitalValid && isPAInteriorValid && isAPCapitalValid && isAPInteriorValid &&
                      isAMCapitalValid && isAMInteriorValid && isRRCapitalValid && isRRInteriorValid &&
                      isAMInteriorValid2 && isACCapitalValid && isACInteriorValid && isDFCapitalValid &&
                      isDFInteriorValid && isGOCapitalValid && isDFInteriorValid2 && isGOCapitalValid2 &&
                      isGOInteriorValid && isROCapitalValid && isROInteriorValid && isTOCapitalValid &&
                      isTOInteriorValid && isMTCapitalValid && isMTInteriorValid && isROInteriorValid2 &&
                      isROInteriorValid3 && isMSCapitalValid && isMSInteriorValid && isPRCapitalValid &&
                      isPRInteriorValid && isSCCapitalValid && isSCInteriorValid && isRSCapitalValid &&
                      isRSInteriorValid && isPickupValid && 
                      isSPCapitalExtendedZoneValid && isSPInteriorExtendedZoneValid && 
                      isRJCapitalExtendedZoneValid && isRJInteriorExtendedZoneValid && 
                      isMGCapitalExtendedZoneValid && isMGInteriorExtendedZoneValid && isESCapitalExtendedZoneValid && isESInteriorExtendedZoneValid
        }
    
        onIsValid(isValid)
    }, [
        isPickupValid,
        // MX
        isSuperSaverValid, isLocalValid, isPremiumValid, isEconomyValid,
        isSuperSaverExtendedZoneValid, isLocalExtendedZoneValid, isPremiumExtendedZoneValid, isEconomyExtendedZoneValid,
        
        // BR
        isSPCapitalValid, isSPInteriorValid, isRJCapitalValid, isRJInteriorValid, isMGCapitalValid, isMGInteriorValid, isESCapitalValid, isESInteriorValid, ,isBACapitalValid, isBAInteriorValid, isSECapitalValid, isSEInteriorValid, isPECapitalValid, isPEInteriorValid, isALCapitalValid,
        isALInteriorValid, isPBCapitalValid, isPBInteriorValid, isRNCapitalValid, isRNInteriorValid, isCECapitalValid, isCEInteriorValid, isPICapitalValid, isPIInteriorValid, isMACapitalValid, isMAInteriorValid, isPACapitalValid,
        isPAInteriorValid, isAPCapitalValid, isAPInteriorValid, isAMCapitalValid, isAMInteriorValid, isRRCapitalValid, isRRInteriorValid, isAMInteriorValid2, isACCapitalValid, isACInteriorValid, isDFCapitalValid, isDFInteriorValid,
        isGOCapitalValid, isDFInteriorValid2, isGOCapitalValid2, isGOInteriorValid, isROCapitalValid, isROInteriorValid, isTOCapitalValid, isTOInteriorValid, isMTCapitalValid, isMTInteriorValid, isROInteriorValid2, isROInteriorValid3,
        isMSCapitalValid, isMSInteriorValid, isPRCapitalValid, isPRInteriorValid, isSCCapitalValid, isSCInteriorValid, isRSCapitalValid, isRSInteriorValid, isSPCapitalExtendedZoneValid, isSPInteriorExtendedZoneValid,
        isRJCapitalExtendedZoneValid, isRJInteriorExtendedZoneValid, isMGCapitalExtendedZoneValid, isMGInteriorExtendedZoneValid, isESCapitalExtendedZoneValid, isESInteriorExtendedZoneValid
    ])

    return (
        <div>
            {serviceTypeCategories.map(serviceTypeCategory => {
                const categoryServiceData = shippingLabelTablesData.find(({category}) => category === serviceTypeCategory)
                const categoryExtendedZoneServiceData = extendedZoneTablesData.find(({category}) => category === serviceTypeCategory)

                const categoryService = shippingLabelServices.find(({service_type_category}) => service_type_category === serviceTypeCategory)
                const categoryExtendedZoneService = extendedZoneServices.find(({service_type_category}) => service_type_category === serviceTypeCategory)

                return (
                    <div key={`national-shipping-label-service-${serviceTypeCategory}`} className="flex flex-col gap-2 mb-5">
                        <div>
                            <div className="flex gap-1">
                                <h2 className="text-xl text-gray-500"><span className="text-red-500">*</span>{i18n.t(`billing.national_shipping_label_services.titles.${serviceTypeCategory}`)}</h2>

                                {validateServiceStatus(SERVICE_TYPES.SERVICE_SHIPPING_LABEL, serviceTypeCategory) ? (
                                    <CheckCircleIcon className="h-5 w-5 text-green-500"/>
                                ) : (
                                    <ExclamationCircleIcon className="h-5 w-5 text-yellow-500"/>
                                )}
                            </div>

                            <RangeBillingPriceConfigTable
                                headers={tableHeaders}
                                priceConfiguration={categoryServiceData.data}
                                currency={getCurrency(warehouse?.country)}
                                onUpdatePriceConfiguration={newTableData => handleOnUpdateData(newTableData, categoryService)}
                                onIsValid={(isValid) => handleOnIsValid(SERVICE_TYPES.SERVICE_SHIPPING_LABEL, serviceTypeCategory, isValid)}
                                availableUnitOptions={[UNITS_IDS.TOTAL, UNITS_IDS.EXCESS_PER_UNIT]}
                                unitOptionsLabels={unitOptionsLabels}
                            />
                        </div>

                        <div>
                            <div className="flex gap-1">
                                <h2 className="text-xl text-gray-500"><span className="text-red-500">*</span>{i18n.t(`billing.national_shipping_label_services.extended_zone_titles.${serviceTypeCategory}`)}</h2>

                                {validateServiceStatus(SERVICE_TYPES.SERVICE_SHIPPING_LABEL_EXTENDED_ZONE, serviceTypeCategory) ? (
                                    <CheckCircleIcon className="h-5 w-5 text-green-500"/>
                                ) : (
                                    <ExclamationCircleIcon className="h-5 w-5 text-yellow-500"/>
                                )}
                            </div>

                            <RangeBillingPriceConfigTable
                                headers={tableHeaders}
                                priceConfiguration={categoryExtendedZoneServiceData.data}
                                currency={getCurrency(warehouse?.country)}
                                onUpdatePriceConfiguration={newTableData => handleOnUpdateData(newTableData, categoryExtendedZoneService)}
                                onIsValid={(isValid) => handleOnIsValid(SERVICE_TYPES.SERVICE_SHIPPING_LABEL_EXTENDED_ZONE, serviceTypeCategory, isValid)}
                                availableUnitOptions={[UNITS_IDS.TOTAL]}
                                unitOptionsLabels={unitOptionsLabels}
                            />
                        </div>
                    </div>
                )
            })}

            <div>
                <div className="flex gap-1">
                    <h2 className="text-xl text-gray-500"><span className="text-red-500">*</span>{i18n.t("billing.national_shipping_label_services.titles.PICKUP")}</h2>

                    {validateServiceStatus(SERVICE_TYPES.SERVICE_SHIPPING_LABEL_PICKUP, null) ? (
                        <CheckCircleIcon className="h-5 w-5 text-green-500"/>
                    ) : (
                        <ExclamationCircleIcon className="h-5 w-5 text-yellow-500"/>
                    )}
                </div>

                <RangeBillingPriceConfigTable
                    headers={tableHeaders}
                    priceConfiguration={pickupTableData}
                    currency={getCurrency(warehouse?.country)}
                    onUpdatePriceConfiguration={newTableData => handleOnUpdateData(newTableData, pickupService)}
                    onIsValid={(isValid) => handleOnIsValid(SERVICE_TYPES.SERVICE_SHIPPING_LABEL_PICKUP, null, isValid)}
                    availableUnitOptions={[UNITS_IDS.TOTAL]}
                    unitOptionsLabels={pickupOptionsLabels}
                />
            </div>

        </div>
    )
}

export default NationalShippingLabelServices