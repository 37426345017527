import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { cloneDeep } from "lodash"
import { CheckCircleIcon, ExclamationCircleIcon } from "@heroicons/react/outline"

import { findOrCreateBillingService, getCurrency } from "../utils"
import { SERVICE_TYPES } from "../constants"
import RangeBillingPriceConfigTable from "../RangeBillingPriceConfigTable"
import { UNITS_IDS } from "../RangeBillingPriceConfigTable/constants"

const ShippingInsuranceServices = ({storeBillingServices, onUpdateStoreBillingServices, onIsValid, storeId, warehouse}) => {
    const { i18n } = useTranslation()

    const tableHeaders = [
        {label: i18n.t("billing.table_headers.from_piece"), id: 'from'},
        {label: i18n.t("billing.table_headers.to_piece"), id: 'to'},
        {label: i18n.t("billing.table_headers.percentage"), id: 'price'},
        {label: i18n.t("billing.table_headers.unit"),id: 'unit'}
    ]

    const [isShippingInsuranceValid, setIsShippingInsuranceValid] = useState(false)

    const shippingInsuranceService = findOrCreateBillingService({serviceType: SERVICE_TYPES.SERVICE_SHIPPING_INSURANCE, billingServices: storeBillingServices, currency: getCurrency(warehouse?.country), storeId: storeId, warehouseId: warehouse?.id})

    const shippingInsuranceTableData = shippingInsuranceService?.price_configuration.map(priceRange => {
        return {
            from: {value: priceRange.from, isDisabled: true},
            to: {value: priceRange.to, isDisabled: true},
            price: {value: priceRange.price * 100},
            price_mode: {value: priceRange.price_mode},
            excess: {value: priceRange.excess},
            range_exclusion: {value: priceRange.range_exclusion},
        }
    })

    const unitOptionsLabels = {
        TOTAL: i18n.t("billing.shipping_insurance_services.unit_options.TOTAL")
    }

    const handleOnUpdateData = (serviceType, newTableData, service) => {
        let newStoreBillingServices = cloneDeep(storeBillingServices)

        const newPriceConfiguration = newTableData.map(priceRangeData => {
            return {
                from: priceRangeData.from.value,
                to: priceRangeData.to.value,
                price: priceRangeData.price.value / 100,
                price_mode: priceRangeData.price_mode.value,
                excess: priceRangeData.excess.value,
                range_exclusion: priceRangeData.range_exclusion.value
            }
        })

        const serviceExists = newStoreBillingServices.some(({service_type}) => service_type === serviceType)

        if(serviceExists){
            newStoreBillingServices = newStoreBillingServices.map(billingService => {
                if(billingService.service_type === serviceType){
                    billingService.price_configuration = newPriceConfiguration
                }
                return billingService
            })
        } else{
            const newService = cloneDeep(service)
            newService.price_configuration = newPriceConfiguration
            newStoreBillingServices.push(newService)
        }

        onUpdateStoreBillingServices(newStoreBillingServices)
    }

    useEffect(() => {
        onIsValid(isShippingInsuranceValid)
    }, [isShippingInsuranceValid])

    return (
        <div className="flex flex-col gap-5">
            <div>
                <div className="flex gap-1">
                    <h2 className="text-xl text-gray-500"><span className="text-red-500">*</span>{i18n.t("billing.shipping_insurance_services.shipping_insurance_title")}</h2>

                    {isShippingInsuranceValid ? (
                        <CheckCircleIcon className="h-5 w-5 text-green-500"/>
                    ) : (
                        <ExclamationCircleIcon className="h-5 w-5 text-yellow-500"/>
                    )}
                </div>

                <RangeBillingPriceConfigTable
                    headers={tableHeaders}
                    priceConfiguration={shippingInsuranceTableData}
                    currency={"%"}
                    onUpdatePriceConfiguration={newTableData => handleOnUpdateData(SERVICE_TYPES.SERVICE_SHIPPING_INSURANCE, newTableData, shippingInsuranceService)}
                    onIsValid={setIsShippingInsuranceValid}
                    availableUnitOptions={[UNITS_IDS.TOTAL]}
                    unitOptionsLabels={unitOptionsLabels}
                />
            </div>
        </div>
    )
}

export default ShippingInsuranceServices