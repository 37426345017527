import { useContext, useEffect, useRef, useState } from "react"
import { useParams, useHistory } from "react-router-dom"
import { cloneDeep } from "lodash"
import { Button, Loader, PageTopBar, PageView } from "../../components"
import { UserContext } from "../../hooks/UserContext"
import { fetchStore } from "../../services"
import { useQuery } from "react-query"
import { useTranslation } from "react-i18next"
import { BILLING_STORE_CONFIGS, BILLING_STORE_CONFIG_DUPLICATE } from "../../navigation/constants"
import { Link } from "react-router-dom/cjs/react-router-dom.min"
import { LeftOutlined } from '@ant-design/icons'
import { CheckCircleIcon, ExclamationCircleIcon } from "@heroicons/react/outline"
import { BillingConfigTab } from "./BillingConfigTab"
import { fetchStoreBillingServices, fetchStoreShippingPackages, createStoreBillingConfiguration, fetchStoresWithBillingConfigurations } from "../../services"
import { fetchWarehouse, fetchWarehouseVolumeCategories } from "../../services/warehouseSevices"
import { CONFIG_CUTOFF_MODES } from "../../components/Billing/constants"
import { Notification, ConfirmDialog } from "../../components"
import { DuplicateConfigFromStoreModal } from "./DuplicateConfigFromStoreModal"

export const BillingStoreConfigTabs = () => {
  const {
    isLoading: isLoadingWarehouse,
    isFetching: isFetchingWarehouse,
    error: errorWarehouse,
    data: warehouse,
} = useQuery(['warehouse', warehouse_id], ()=>fetchWarehouse(warehouse_id))
  const { user } = useContext(UserContext)
  const { t, i18n} = useTranslation()
  const history = useHistory()
  const { store_id } = useParams()
  const {
      isLoading,
      isFetching,
      isError,
      error,
      data,
  } = useQuery(['store', store_id], ()=>fetchStore(store_id))
  const [validForm, setValidForm] = useState('default')
  const [currentTab, setCurrentTab] = useState(0)
  const [notificationMessage, setNotificationMessage] = useState({show: false, type: "error", title: ""})
  const [tabOptions, setTabOptions] = useState([])
  const [tabStates, setTabStates] = useState([])
  const [storeBillingServices, setStoreBillingServices] = useState(null)
  const [storeShippingPackages, setStoreShippingPackages] = useState(null)
  const [warehouseLocationVolumeCategories, setWarehouseLocationVolumeCategories] = useState(null)
  const {warehouse_id} = useParams()
  const [storeBillingConfiguration, setStoreBillingConfiguration] = useState({
    cutoff_interval: null,
    cutoff_mode: CONFIG_CUTOFF_MODES.CUTOFF_MODE_END_OF_INTERVAL
  })
  const {billing_configuration_id} = useParams()
  const handleSelectedItem = (value) => {
    setValidForm(value)
  }
  const [isLoadingData, setIsLoadingData] = useState(true)
  const baseValidStatuses = {
    billing_date: false,
    storage: false,
    pick_and_pack: false,
    shipping_label: false,
    international_shipping_label: false,
    replenishment: false,
    returns: false,
    work_orders: false
  }
  const [tabsValidStatuses, setTabsValidStatuses] = useState(baseValidStatuses)
  const [showConfirmSaveDialog, setShowConfirmSaveDialog] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [showCopyFromOtherStoreDialog, setShowCopyFromOtherStoreDialog] = useState(false)
  const isConfigCreatedRef = useRef(false)

  const onContinue = async (currentKey) => {
    const currentIndex = tabOptions.findIndex((tab) => tab.key === currentKey)
    const nextIndex = tabOptions.length > currentIndex + 1 ? currentIndex + 1 : currentIndex
    setTabOptions((prevOptions) => {
      return prevOptions.map((tab, index) => {
        if (index === currentIndex) {
          tab.validated = true
        }
        if (index === nextIndex) {
          tab.disabled = false
        }
        return tab
      })
    })

    if(currentIndex === nextIndex){
      setShowConfirmSaveDialog(true)
    }

    setCurrentTab(nextIndex)
  }

  useEffect(() => {
    const fetchData = async () => {
      setIsLoadingData(true)

      let defaultServices = null

      if(billing_configuration_id !== undefined){
        const fetchStoreBillingServicesParams = {store_billing_configuration_id: billing_configuration_id, warehouse_id: warehouse_id, per_page: 200}
        const fetchStoreBillingServicesResult = await fetchStoreBillingServices(fetchStoreBillingServicesParams)
        defaultServices = fetchStoreBillingServicesResult.store_billing_services
      }
      else{
        const fetchStoresWithBillingConfigurationsParams = {having_current_billing_configuration: true, warehouse_id: warehouse_id, per_page: 1}
        const fetchStoresWithBillingConfigurationsResult = await fetchStoresWithBillingConfigurations(fetchStoresWithBillingConfigurationsParams)

        if (fetchStoresWithBillingConfigurationsResult.stores.length === 0) {
          defaultServices = []
        }
        else {
          const fetchStoreBillingServicesParams = {
            warehouse_id: warehouse_id,
            store_billing_configuration_id: fetchStoresWithBillingConfigurationsResult.stores[0].current_billing_configuration_with_warehouse_ids.id,
            per_page: 200
          }
          const fetchStoreBillingServicesResult = await fetchStoreBillingServices(fetchStoreBillingServicesParams)
          defaultServices = fetchStoreBillingServicesResult.store_billing_services
        }
      }

      defaultServices = defaultServices.map(billingService => ({
        store_id: store_id,
        warehouse_id: warehouse_id,
        service_type: billingService.service_type,
        price_configuration: billingService.price_configuration,
        currency: billingService.currency,
        service_type_category: billingService.service_type_category
      }))

      const fetchStoreShippingPackagesParams = {warehouse_id: warehouse_id}
      const currentStoreShippingPackages = await fetchStoreShippingPackages(store_id, fetchStoreShippingPackagesParams)

      const currentWarehouseLocationVolumeCategories = await fetchWarehouseVolumeCategories(warehouse_id)

      setStoreBillingServices(defaultServices)
      setStoreShippingPackages(currentStoreShippingPackages)
      setWarehouseLocationVolumeCategories(currentWarehouseLocationVolumeCategories)

      setIsLoadingData(false)
    }
    fetchData()
  }, [warehouse_id, store_id, billing_configuration_id])

  useEffect(() => {
    resetTabOptions()

    if (warehouse?.country === 'BR') {
      setTabsValidStatuses(prevStatuses => ({
        ...prevStatuses,
        difal: false,
        shipping_insurance: false
      }))
    }else{
      setTabsValidStatuses(baseValidStatuses)
    }

    setStoreBillingConfiguration(prevStatuses => ({
      ...prevStatuses,
      currency: warehouse?.country == 'MX' ? 'MXN' : 'BRL',
    }))
  }, [warehouse])  

  const resetTabOptions = () => {
    let options = []
    options.push(
      {
        key: 'billing_date',
        disabled: false,
        validated: false,
      },
      {
        key: 'storage',
        disabled: true,
        validated: false,
      },
      {
        key: 'pick_and_pack',
        disabled: true,
        validated: false,
      },
      {
        key: 'shipping_label',
        disabled: true,
        validated: false,
      },
      {
        key: 'international_shipping_label',
        disabled: true,
        validated: false,
      },
      {
        key: 'replenishment',
        disabled: true,
        validated: false,
      },
      {
        key: 'returns',
        disabled: true,
        validated: false,
      },
      {
        key: 'work_orders',
        disabled: true,
        validated: false,
      }
    )

    if (warehouse?.country === 'BR'){
      options.push(
        {
          key: 'difal',
          disabled: true,
          validated: false,
        },
        {
          key: 'shipping_insurance',
          disabled: true,
          validated: false,
        }
      )
    }
    setTabOptions(options)
  }

  useEffect(() => {
    const unblockPage = history.block(() => {
      const exitCurrentPage = isConfigCreatedRef.current ? true : window.confirm(t("billing.confirm_exit_dialog.title"))

      if(exitCurrentPage){
        unblockPage()
        return true
      } else {
        return false
      }
    })
  }, [history, t])

  const renderTabTitle = (option, index) => {
    const isTabValid = tabsValidStatuses[option.key]

    const icon = option.disabled
      ? <ExclamationCircleIcon className="h-4 w-4 text-blue-gray-400" />
      : isTabValid
      ? <CheckCircleIcon className="h-4 w-4 text-green-400" />
      : <ExclamationCircleIcon className="h-4 w-4 text-red-400" />
    const textStyle = option.disabled ? 'text-gray-400' : currentTab === index ? 'text-blue-500' : ''
  
    return (
      <div className="flex items-center">
        {icon}
        <span className={`ml-2 text-base ${textStyle}`}>{t(`billing.tabTitles.${option.key}`)}</span>
      </div>
    )
  }

  const handleOnTabChange = (option, tabIndex) => {
    if(!option.disabled){
      setCurrentTab(tabIndex)
    }
  }

  const handleOnIsValid = (configKey, isValid) => {
    const newTabsValidStatuses = {...tabsValidStatuses}
    newTabsValidStatuses[configKey] = isValid
    setTabsValidStatuses(newTabsValidStatuses)
  }

  const handleOnSave = async () => {
    const allStatuses = Object.values(tabsValidStatuses)

    const areServicesWarehouseAndStoreValid = storeBillingServices?.length > 0 && storeBillingServices.every(billingService => (
      billingService.store_id == store_id && billingService.warehouse_id == warehouse_id
    ))

    const areAllValid = allStatuses?.length > 0 && allStatuses.every(status => status === true) && areServicesWarehouseAndStoreValid

    if(!areAllValid){
      setNotificationMessage({show: true, type: "error", title: t("billing.create_invalid_content_error_title")})
      setShowConfirmSaveDialog(false)
      return
    }

    setIsSaving(true)

    try{
      await createStoreBillingConfiguration(store_id, warehouse_id, storeBillingConfiguration, storeBillingServices)
      isConfigCreatedRef.current = true
      history.push(BILLING_STORE_CONFIGS.replace(':warehouse_id', warehouse_id).replace(':store_id', store_id))
    } catch(error){
      isConfigCreatedRef.current = false
      setNotificationMessage({show: true, type: "error", title: t("billing.create_error_title")})
    }

    setShowConfirmSaveDialog(false)
    setIsSaving(false)
  }

  const handleOnUpdateBillingServices = (newStoreBillingServices) => {
    setStoreBillingServices(cloneDeep(newStoreBillingServices))
  }

  const handleOnUpdateBillingConfiguration = (newStoreBillingConfiguration) => {
    setStoreBillingConfiguration(cloneDeep(newStoreBillingConfiguration))
  }

  const handleCopyFromOtherStore = () => {
    setShowCopyFromOtherStoreDialog(true)
  }

  const onConfirmCopyFromOtherStore = (selectedBillingConfigId) => {
    history.push(BILLING_STORE_CONFIG_DUPLICATE.replace(':warehouse_id', warehouse_id).replace(':store_id', store_id).replace(':billing_configuration_id', selectedBillingConfigId))
    setShowCopyFromOtherStoreDialog(false)
    setCurrentTab(0)
    resetTabOptions()
    if (warehouse?.country === 'BR') {
      setTabsValidStatuses(prevStatuses => ({
        ...prevStatuses,
        difal: false,
        shipping_insurance: false
      }))

      setStoreBillingConfiguration(prevStatuses => ({
        ...prevStatuses,
        currency: warehouse?.country == 'MX' ? 'MXN' : 'BRL',
      }))
    }
  }

  return (
    <>
      <PageView
        topMenu={
          <PageTopBar marginClass="my-1">
            <div className="flex flex-row items-start">
              <Link to={BILLING_STORE_CONFIGS.replace(':warehouse_id', warehouse_id).replace(':store_id', store_id)} className="text-black hover:text-black mt-2">
                <LeftOutlined className="mr-3"/>
              </Link>
              <div className="flex flex-col">
                <label className="font-semibold text-gray200 text-lg">{t('billing.title')}</label>
                <label className="text-gray-300">{data?.name}</label>
              </div>
            </div>
            <div>
              <Button type={"primary"} disabled={false} onClick={() => handleCopyFromOtherStore()} >Copiar configuración de otra tienda</Button>
              </div>
          </PageTopBar>
        }
        childrenFullWidth={true}
        topMenuFullWidth={true}
      >
        {isLoading || isLoadingData || isLoadingWarehouse ? (
            <><Loader show={true}></Loader></>
        ) : isError ? (
            <>Error: {error.message}</>
        ) : (
          <div className="flex rounded-lg bg-white overflow-hidden shadow w-full">
            <div className="w-1/6 p-4 border-r">
              <div className="flex flex-col">
                {tabOptions.length > 0 && tabOptions.map((option, index) => {
                  return (
                    <div
                      key={index}
                      className={`cursor-pointer mb-4 p-2`}
                      onClick={() => handleOnTabChange(option, index)}
                    >
                      {renderTabTitle(option, index)}
                    </div>
                  )
                })}
              </div>
            </div>
            <div className="w-5/6 p-4">
              {tabOptions.length > 0 && tabOptions.map((option, index) => {
                return (
                  <div className="w-full" key={index}>
                    {currentTab === index && (
                        <BillingConfigTab
                          configKey={option.key}
                          onContinue={onContinue}
                          onUpdateBillingServices={handleOnUpdateBillingServices}
                          onUpdateBillingConfiguration={handleOnUpdateBillingConfiguration}
                          storeBillingServices={storeBillingServices}
                          storeBillingConfiguration={storeBillingConfiguration}
                          storeShippingPackages={storeShippingPackages}
                          warehouseLocationVolumeCategories={warehouseLocationVolumeCategories}
                          currentIndex={index}
                          lastIndex={tabOptions.length - 1}
                          onIsValid={handleOnIsValid}
                          storeId={store_id}
                          warehouse={warehouse}
                        />
                    )}
                  </div>
                )
              })}
            </div>
          </div>
        )}
      </PageView>

      <Notification
        show={notificationMessage.show}
        title={notificationMessage.title}
        type={notificationMessage.type}
        setShow={showNotification => setNotificationMessage({...notificationMessage, show: showNotification})}
      />

      <ConfirmDialog
        open={showConfirmSaveDialog}
        setOpen={setShowConfirmSaveDialog}
        title={t("billing.save_dialog.title")}
        description={t("billing.save_dialog.description")}
        confirmLabel={t("billing.save_dialog.confirm_button")}
        cancelLabel={t("billing.save_dialog.cancel_button")}
        onConfirm={handleOnSave}
        onCancel={() => setShowConfirmSaveDialog(false)}
        loading={isSaving}
      />

      <DuplicateConfigFromStoreModal open={showCopyFromOtherStoreDialog} setOpen={setShowCopyFromOtherStoreDialog} onConfirm={onConfirmCopyFromOtherStore}/>
    </>
  )
}
